import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class TransactionIdService {
  private _transactionId: string;

  public get transactionId(): string {
    const initAndReturnUuid = () => {
      this._transactionId = uuid();
      return this._transactionId;
    };

    return !!this._transactionId ? this._transactionId : initAndReturnUuid();
  }

  public resetTransactionId(): void {
    this._transactionId = null;
  }
}
