import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionType } from '../../../components/dynamic-section-creator/sections/section.type';

@Injectable()
export class TrusteeDataSideEffects implements OnDestroy {
  private formGroup: FormGroup;
  private trusteeFormGroup: FormGroup;
  private contactDataForm: FormGroup;

  private permanentSameAsSeatSubscriptions: Subscription;
  private mailingSameAsPermanentSubscriptions: Subscription;
  private contactPrimaryEmailSameAsCompanySubscription: Subscription;
  private contactPrimaryMobileNumberSameAsCompanySubscription: Subscription;
  private contactLandLinePhoneNumberSameAsCompanySubscription: Subscription;
  private contactDataFormEmailChangesSubscriptions: Subscription;
  private contactDataFormPhoneNumberChangesSubscriptions: Subscription;
  private typeOfIdCardSubscription: Subscription;
  private contactDataFormLandingPhoneNumberChangesSubscriptions: Subscription;

  public ngOnDestroy(): void {
    if (!!this.permanentSameAsSeatSubscriptions) {
      this.permanentSameAsSeatSubscriptions.unsubscribe();
    }
    if (!!this.mailingSameAsPermanentSubscriptions) {
      this.mailingSameAsPermanentSubscriptions.unsubscribe();
    }
    if (!!this.contactPrimaryEmailSameAsCompanySubscription) {
      this.contactPrimaryEmailSameAsCompanySubscription.unsubscribe();
    }
    if (!!this.contactPrimaryMobileNumberSameAsCompanySubscription) {
      this.contactPrimaryMobileNumberSameAsCompanySubscription.unsubscribe();
    }
    if (!!this.contactLandLinePhoneNumberSameAsCompanySubscription) {
      this.contactLandLinePhoneNumberSameAsCompanySubscription.unsubscribe();
    }
    if (!!this.typeOfIdCardSubscription) {
      this.typeOfIdCardSubscription.unsubscribe();
    }
    if (!!this.contactDataFormEmailChangesSubscriptions) {
      this.contactDataFormEmailChangesSubscriptions.unsubscribe();
    }
    if (!!this.contactDataFormPhoneNumberChangesSubscriptions) {
      this.contactDataFormPhoneNumberChangesSubscriptions.unsubscribe();
    }
    if (!!this.contactDataFormLandingPhoneNumberChangesSubscriptions) {
      this.contactDataFormLandingPhoneNumberChangesSubscriptions.unsubscribe();
    }
  }

  public addSideEffect(form: FormGroup): void {
    this.formGroup = form;
    this.trusteeFormGroup = this.extractTrusteeDataForm(form);

    this.permanentSameAsSeatSubscriptions = this.createPermanentSameAsSeatSubscriptions();
    this.mailingSameAsPermanentSubscriptions = this.createMailingSameAsPermanentSubscriptions();
    this.contactPrimaryEmailSameAsCompanySubscription = this.createContactPrimaryEmailSameAsCompanySubscription();
    this.contactPrimaryMobileNumberSameAsCompanySubscription = this.createContactPrimaryMobileSameAsCompanySubscription();
    this.contactLandLinePhoneNumberSameAsCompanySubscription = this.createContactLandLinePhoneSameAsCompanySubscription();
    this.typeOfIdCardSubscription = this.createTypeOfIdCardSubscription();
  }

  private createContactLandLinePhoneSameAsCompanySubscription(): Subscription {
    let companyDataPrimaryLandLineNumberValueChangesSubscription: Subscription;
    const primaryLandLineNumberFormControl = <FormControl>(
      this.trusteeFormGroup.get('contact.landLinePhoneNumber')
    );
    const companyDataPrimaryLandLineFormControl = <FormControl>(
      this.formGroup.get('companyData.contactDetails.primaryLandLineNumber')
    );

    const onLandLinePhoneNumberSameAsCompanyLandLine = () => {
      primaryLandLineNumberFormControl.patchValue(companyDataPrimaryLandLineFormControl.value);
      companyDataPrimaryLandLineNumberValueChangesSubscription = this.createCopyFormControlValueSubscription(
        companyDataPrimaryLandLineFormControl,
        primaryLandLineNumberFormControl
      );
      primaryLandLineNumberFormControl.disable();
    };

    const onLandLinePhoneNumberNotSameAsCompanyLandLine = () => {
      if (companyDataPrimaryLandLineNumberValueChangesSubscription) {
        companyDataPrimaryLandLineNumberValueChangesSubscription.unsubscribe();
      }
      primaryLandLineNumberFormControl.patchValue('');
      primaryLandLineNumberFormControl.enable();
    };

    return this.trusteeFormGroup
      .get('landLinePhoneNumberSameAsCompanyLandLine')
      .valueChanges.subscribe(value => {
        value
          ? onLandLinePhoneNumberSameAsCompanyLandLine()
          : onLandLinePhoneNumberNotSameAsCompanyLandLine();
      });
  }

  private createContactPrimaryMobileSameAsCompanySubscription(): Subscription {
    let companyDataPrimaryMobileNumberValueChangesSubscription: Subscription;
    let companyDataAdditionalMobileNumberValueChangesSubscription: Subscription;

    const primaryMobileNumberFormControl = <FormControl>(
      this.trusteeFormGroup.get('contact.primaryMobileNumber')
    );
    const additionalMobileNumberFormControl = <FormControl>(
      this.trusteeFormGroup.get('contact.additionalMobileNumber')
    );

    const companyDataPrimaryMobileFormControl = <FormControl>(
      this.formGroup.get('companyData.contactDetails.primaryMobileNumber')
    );

    const companyDataAdditionalMobileNumberFormControl = <FormControl>(
      this.formGroup.get('companyData.contactDetails.additionalMobileNumber1')
    );

    const onPrimaryMobileNumberSameAsCompanyMobile = () => {
      primaryMobileNumberFormControl.patchValue(companyDataPrimaryMobileFormControl.value);
      additionalMobileNumberFormControl.patchValue(
        companyDataAdditionalMobileNumberFormControl.value
      );
      companyDataPrimaryMobileNumberValueChangesSubscription = this.createCopyFormControlValueSubscription(
        companyDataPrimaryMobileFormControl,
        primaryMobileNumberFormControl
      );
      companyDataAdditionalMobileNumberValueChangesSubscription = this.createCopyFormControlValueSubscription(
        companyDataAdditionalMobileNumberFormControl,
        additionalMobileNumberFormControl
      );

      primaryMobileNumberFormControl.disable();
      additionalMobileNumberFormControl.disable();
    };

    const onPrimaryMobileNumberNotSameAsCompanyMobile = () => {
      if (companyDataPrimaryMobileNumberValueChangesSubscription) {
        companyDataPrimaryMobileNumberValueChangesSubscription.unsubscribe();
        companyDataAdditionalMobileNumberValueChangesSubscription.unsubscribe();
      }
      primaryMobileNumberFormControl.patchValue('');
      primaryMobileNumberFormControl.enable();

      additionalMobileNumberFormControl.patchValue('');
      additionalMobileNumberFormControl.enable();
    };

    return this.trusteeFormGroup
      .get('primaryMobileNumberSameAsCompanyMobile')
      .valueChanges.subscribe(value => {
        value
          ? onPrimaryMobileNumberSameAsCompanyMobile()
          : onPrimaryMobileNumberNotSameAsCompanyMobile();
      });
  }

  private createContactPrimaryEmailSameAsCompanySubscription(): Subscription {
    const primaryEmailFormControl = <FormControl>this.trusteeFormGroup.get('contact.primaryEmail');
    const companyDataPrimaryEmailFormControl = <FormControl>(
      this.formGroup.get('companyData.contactDetails.primaryEmail')
    );
    let companyDataPrimaryEmailValueChangesSubscription: Subscription;

    const onPrimaryEmailSameAsCompanyEmail = () => {
      primaryEmailFormControl.patchValue(companyDataPrimaryEmailFormControl.value);
      companyDataPrimaryEmailValueChangesSubscription = this.createCopyFormControlValueSubscription(
        companyDataPrimaryEmailFormControl,
        primaryEmailFormControl
      );
      primaryEmailFormControl.disable();
    };

    const onPrimaryEmailNotSameAsCompanyEmail = () => {
      if (companyDataPrimaryEmailValueChangesSubscription) {
        companyDataPrimaryEmailValueChangesSubscription.unsubscribe();
      }
      primaryEmailFormControl.patchValue('');
      primaryEmailFormControl.enable();
    };

    return this.trusteeFormGroup
      .get('primaryEmailSameAsCompanyEmail')
      .valueChanges.subscribe(value => {
        value ? onPrimaryEmailSameAsCompanyEmail() : onPrimaryEmailNotSameAsCompanyEmail();
      });
  }

  private createMailingSameAsPermanentSubscriptions(): Subscription {
    const permanentForm = <FormGroup>this.trusteeFormGroup.get('address');
    const mailingForm = <FormGroup>this.trusteeFormGroup.get('mailing');
    let permanentFormValueChangesSubscription: Subscription;

    const onMailingSameAsPermanentFunction = () => {
      this.copyAddressForm(permanentForm, mailingForm);
      permanentFormValueChangesSubscription = permanentForm.valueChanges.subscribe(value => {
        mailingForm.patchValue(value);
      });
      mailingForm.disable();
    };

    const onMailingNotSameAsPermanentFunction = () => {
      if (permanentFormValueChangesSubscription) {
        permanentFormValueChangesSubscription.unsubscribe();
      }
      mailingForm.patchValue({ addressField: '' });
      mailingForm.enable();
    };

    return this.trusteeFormGroup.get('mailingSameAsPermanent').valueChanges.subscribe(value => {
      value ? onMailingSameAsPermanentFunction() : onMailingNotSameAsPermanentFunction();
    });
  }

  private createPermanentSameAsSeatSubscriptions(): Subscription {
    const permanentForm = <FormGroup>this.trusteeFormGroup.get('address');
    const seatForm = <FormGroup>this.formGroup.get('companyData.seat');
    let seatFormValueChangesSubscription: Subscription;

    const onPermanentSameAsSeatFunction = () => {
      this.copyAddressForm(seatForm, permanentForm);
      seatFormValueChangesSubscription = seatForm.valueChanges.subscribe(value => {
        permanentForm.patchValue(value);
      });
      permanentForm.disable();
    };

    const onPermanentNotSameAsSeatFunction = () => {
      if (seatFormValueChangesSubscription) {
        seatFormValueChangesSubscription.unsubscribe();
      }
      permanentForm.patchValue({ addressField: '' });
      permanentForm.enable();
    };

    return this.trusteeFormGroup.get('permanentSameAsSeat').valueChanges.subscribe(value => {
      value ? onPermanentSameAsSeatFunction() : onPermanentNotSameAsSeatFunction();
    });
  }

  private createTypeOfIdCardSubscription(): Subscription {
    const trusteeDataForm = this.extractTrusteeDataForm(this.formGroup);
    const IDCardNumberFormControl = <FormControl>trusteeDataForm.get('documents.IDCardNumber');

    return trusteeDataForm.get('documents.IDCardType').valueChanges.subscribe(value => {
      if (value === 'id-card') {
        IDCardNumberFormControl.setValidators([
          Validators.required,
          Validators.pattern('^\\d\\d\\d\\d\\d\\d\\D\\D$'),
        ]);
        IDCardNumberFormControl.updateValueAndValidity();
      } else if (value === 'driving-license') {
        IDCardNumberFormControl.setValidators([
          Validators.required,
          Validators.pattern('^\\D\\D\\d\\d\\d\\d\\d\\d$'),
        ]);
        IDCardNumberFormControl.updateValueAndValidity();
      }
    });
  }

  private createCopyFormControlValueSubscription(
    copyFromFormControl: FormControl,
    copyToFormControl: FormControl
  ): Subscription {
    return copyFromFormControl.valueChanges.subscribe(value => {
      copyToFormControl.patchValue(value);
    });
  }

  private copyAddressForm(copyFromForm: FormGroup, copyToForm: FormGroup): void {
    copyToForm.patchValue(copyFromForm.value);
  }

  private extractTrusteeDataForm(form: FormGroup): FormGroup {
    return <FormGroup>form.get(SectionType.TrusteeData);
  }

  public addContactDataFormSideEffect(contactDataForm: FormGroup) {
    this.contactDataForm = contactDataForm;
    this.contactDataFormEmailChangesSubscriptions = this.createContactDataFormEmailChangesSubscriptions();
    this.contactDataFormPhoneNumberChangesSubscriptions = this.createContactDataFormPhoneNumberChangesSubscriptions();
    this.contactDataFormLandingPhoneNumberChangesSubscriptions = this.createContactDataFormLandingPhoneNumberChangesSubscriptions();
  }

  private createContactDataFormEmailChangesSubscriptions(): Subscription {
    const contactDataEmailControl = this.contactDataForm.get('email');
    const primaryEmailControl = this.trusteeFormGroup.get('contact.primaryEmail');
    const primaryEmailSameAsCompanyEmailControl = this.trusteeFormGroup.get(
      'primaryEmailSameAsCompanyEmail'
    );

    const isContactDataEmailControlValid = () => {
      return contactDataEmailControl.valid && !!contactDataEmailControl.value;
    };

    const onContactDataEmailControlValid = () => {
      if (!!this.contactPrimaryEmailSameAsCompanySubscription) {
        this.contactPrimaryEmailSameAsCompanySubscription.unsubscribe();
      }
      primaryEmailControl.patchValue(contactDataEmailControl.value);
      primaryEmailControl.disable();
      primaryEmailSameAsCompanyEmailControl.disable();
    };

    const onContactDataEmailControlInvalid = () => {
      primaryEmailControl.reset();
      primaryEmailControl.enable();
      primaryEmailSameAsCompanyEmailControl.enable();
      if (this.contactPrimaryEmailSameAsCompanySubscription.closed) {
        this.contactPrimaryEmailSameAsCompanySubscription = this.createContactPrimaryEmailSameAsCompanySubscription();
      }
    };

    return contactDataEmailControl.valueChanges.subscribe(() => {
      isContactDataEmailControlValid()
        ? onContactDataEmailControlValid()
        : onContactDataEmailControlInvalid();
    });
  }

  private createContactDataFormPhoneNumberChangesSubscriptions(): Subscription {
    const contactDataPhoneNumberControl = this.contactDataForm.get('phoneNumber');
    const primaryMobilePhoneNumberControl = this.trusteeFormGroup.get(
      'contact.primaryMobileNumber'
    );
    const primaryMobileNumberSameAsCompanyMobileControl = this.trusteeFormGroup.get(
      'primaryMobileNumberSameAsCompanyMobile'
    );

    const isContactDataPhoneNumberControlValid = () => {
      return contactDataPhoneNumberControl.valid && !!contactDataPhoneNumberControl.value;
    };

    const onContactDataPhoneNumberControlValid = () => {
      if (!!this.contactPrimaryMobileNumberSameAsCompanySubscription) {
        this.contactPrimaryMobileNumberSameAsCompanySubscription.unsubscribe();
      }
      primaryMobilePhoneNumberControl.patchValue(contactDataPhoneNumberControl.value);
      primaryMobilePhoneNumberControl.disable();
      primaryMobileNumberSameAsCompanyMobileControl.disable();
    };

    const onContactDataPhoneNumberControlInvalid = () => {
      primaryMobilePhoneNumberControl.reset();
      primaryMobilePhoneNumberControl.enable();
      primaryMobileNumberSameAsCompanyMobileControl.enable();
      if (this.contactPrimaryMobileNumberSameAsCompanySubscription.closed) {
        this.contactPrimaryMobileNumberSameAsCompanySubscription = this.createContactPrimaryMobileSameAsCompanySubscription();
      }
    };

    return contactDataPhoneNumberControl.valueChanges.subscribe(() => {
      isContactDataPhoneNumberControlValid()
        ? onContactDataPhoneNumberControlValid()
        : onContactDataPhoneNumberControlInvalid();
    });
  }

  private createContactDataFormLandingPhoneNumberChangesSubscriptions(): Subscription {
    const contactDataPrimaryLandLineNumberControl = this.formGroup.get(
      'companyData.contactDetails.primaryLandLineNumber'
    );
    const landLinePhoneNumberControl = this.trusteeFormGroup.get('contact.landLinePhoneNumber');
    const landLinePhoneNumberSameAsCompanyLandLineControl = this.trusteeFormGroup.get(
      'landLinePhoneNumberSameAsCompanyLandLine'
    );

    landLinePhoneNumberSameAsCompanyLandLineControl.disable();

    const isContactDataPrimaryLandLineNumberControlValid = () => {
      return (
        contactDataPrimaryLandLineNumberControl.valid &&
        !!contactDataPrimaryLandLineNumberControl.value
      );
    };

    const onContactDataPrimaryLandLineNumberControlValid = () => {
      landLinePhoneNumberSameAsCompanyLandLineControl.enable();

      if (this.contactLandLinePhoneNumberSameAsCompanySubscription.closed) {
        this.contactLandLinePhoneNumberSameAsCompanySubscription = this.createContactLandLinePhoneSameAsCompanySubscription();
      }
    };

    const onContactDataPrimaryLandLineNumberControlInvalid = () => {
      landLinePhoneNumberSameAsCompanyLandLineControl.patchValue(false);
      landLinePhoneNumberSameAsCompanyLandLineControl.disable();
      landLinePhoneNumberControl.reset();
      if (!!this.contactLandLinePhoneNumberSameAsCompanySubscription) {
        this.contactLandLinePhoneNumberSameAsCompanySubscription.unsubscribe();
      }
    };

    return contactDataPrimaryLandLineNumberControl.valueChanges.subscribe(() => {
      isContactDataPrimaryLandLineNumberControlValid()
        ? onContactDataPrimaryLandLineNumberControlValid()
        : onContactDataPrimaryLandLineNumberControlInvalid();
    });
  }
}
