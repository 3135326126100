import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _isSpinnerShown = false;

  public get isSpinnerShown(): boolean {
    return this._isSpinnerShown;
  }

  public showSpinner(): void {
    this._isSpinnerShown = true;
  }

  public hideSpinner(): void {
    this._isSpinnerShown = false;
  }
}
