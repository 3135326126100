import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  templateUrl: './popup-browser-dialog.component.html',
})
export class PopupBrowserDialogComponent {
  constructor(private dialogRef: MatDialogRef<PopupBrowserDialogComponent>) {}

  public onResume(): void {
    this.dialogRef.close();
  }
}
