const certificateOfIncorporation = 'companyInfo.documents.certificateOfIncorporation';
const memorandumOfAssociation = 'companyInfo.documents.memorandumOfAssociation';
const articlesOfIncorporation = 'companyInfo.documents.articlesOfIncorporation';
const certificate = 'companyInfo.documents.certificate';
const applicationOfElectronicRegistration =
  'companyInfo.documents.applicationOfElectronicRegistration';
const electronicCheckReceipt = 'companyInfo.documents.electronicCheckReceipt';
const signatureSpecimen = 'companyInfo.documents.signatureSpecimen';

export const documentsCompanyTypeMatching = {
  privateEntrepreneur: [],
  limitedLiability: [certificateOfIncorporation, articlesOfIncorporation, signatureSpecimen],
  newCompany: [
    articlesOfIncorporation,
    certificate,
    applicationOfElectronicRegistration,
    electronicCheckReceipt,
    signatureSpecimen,
  ],
  individualCompany: [certificateOfIncorporation, memorandumOfAssociation, signatureSpecimen],
  cooperative: [certificateOfIncorporation, memorandumOfAssociation, signatureSpecimen],
  generalPartnership: [certificateOfIncorporation, memorandumOfAssociation, signatureSpecimen],
};
