import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { packagesOptions } from '../account-selector.component';
import {CountlyService} from '../../../../../shared/service/countly.service';

@Component({
  selector: 'app-package-selector',
  styleUrls: ['package-selector.component.scss'],
  templateUrl: 'package-selector.component.html',
})
export class PackageSelectorComponent {
  @Input()
  public formGroup: FormGroup;
  public selectedPackage: string;
  public criteria = [
    ['package-accountant'],
    ['package-1', 'package-1-special', 'package-2'],
    ['package-2', 'package-1', 'package-3'],
    ['package-1', 'package-2', 'package-3'],
    ['package-2', 'package-3', 'package-4'],
    ['package-2', 'package-4', 'package-3'],
  ];
  public values: Array<String> = [
    'value-1',
    'value-2',
    'value-3',
    'value-4',
    'value-5',
    'value-6',
    'value-7',
  ];
  public keys: Array<string> = Object.keys(this.criteria);

  constructor(private countlyService: CountlyService) {
  }

  public get matchedCount() {
    return this.criteria[this._selectedTabIndex].length;
  }

  public _selectedTabIndex: number;

  @Input()
  public set selectedTabIndex(selectedOption: any) {
    this._selectedTabIndex = selectedOption;
    this.setPackage(
      selectedOption === packagesOptions.option1.tabIndex ? 'package-accountant' : ''
    );
  }

  public setPackage(packageName: string) {
    this.selectedPackage = packageName;
    const selectedPackageControl = this.formGroup.get('selectedPackage');
    selectedPackageControl.patchValue(packageName);
  }
}
