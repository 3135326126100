import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit, OnDestroy {
  @Input()
  public formGroup: FormGroup;

  @Input()
  public placeholder: string;

  private countryChangeSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public get countryOfBirthControl(): FormControl {
    return <FormControl>this.formGroup.get('countryOfBirth');
  }

  public get countryOfBirthOtherControl(): FormControl {
    return <FormControl>this.formGroup.get('countryOfBirthOther');
  }

  public get countries(): string[] {
    return ['hu', 'usa', 'other'];
  }

  public ngOnInit(): void {
    this.countryChangeSubscription = this.createCountryChangeSubscription();
    this.countryOfBirthOtherControl.disable();
  }

  public ngOnDestroy(): void {
    if (!!this.countryChangeSubscription) {
      this.countryChangeSubscription.unsubscribe();
    }
  }

  public get isCountryOther(): boolean {
    return this.countryOfBirthControl.value === 'other';
  }

  private createCountryChangeSubscription(): Subscription {
    const isCountryUsa = () => {
      return this.countryOfBirthControl.value === 'usa';
    };

    const onCountryHungary = () => {
      this.countryOfBirthOtherControl.reset();
      removeCountryOfBirthUsaError();
      this.countryOfBirthOtherControl.disable();
    };

    const onCountryUsa = () => {
      this.countryOfBirthOtherControl.reset();
      addCountryOfBirthUsaError();
      this.countryOfBirthOtherControl.disable();
    };

    const onCountryOther = () => {
      removeCountryOfBirthUsaError();
      this.countryOfBirthOtherControl.enable();
    };

    const addCountryOfBirthUsaError = () => {
      let errors = this.countryOfBirthControl.errors;
      errors ? (errors.countryOfBirthUsa = true) : (errors = { countryOfBirthUsa: true });
      this.countryOfBirthControl.setErrors(errors);
    };

    const removeCountryOfBirthUsaError = () => {
      if (!!this.countryOfBirthControl.errors) {
        delete this.countryOfBirthControl.errors['countryOfBirthUsa'];
      }
    };

    return this.countryOfBirthControl.valueChanges.subscribe(() => {
      this.changeDetector.detectChanges();
      isCountryUsa() ? onCountryUsa() : this.isCountryOther ? onCountryOther() : onCountryHungary();
    });
  }
}
