import { Injectable } from '@angular/core';
import { SmeonBffService } from './client/smeon-bff.service';
import { RbhuFinalPageQueryV1Model } from '../../model/query-models/rbhu-final-page-query-v1.model';
import { AppConfigService } from '../../app-config.service';

interface RbhuFinalPageDTO {
  pageId: string;
}

@Injectable({
  providedIn: 'root',
})
export class RbhuFinalPageService {
  constructor(private bffService: SmeonBffService, private configService: AppConfigService) {}

  public redirectToThankYouPage(companyName: string, applicationId: string): void {
    const query = new RbhuFinalPageQueryV1Model();
    query.applicationId = applicationId;
    query.companyName = companyName;
    console.log('====== Redirect to thank you page =======');
    window.location.href = 'https://szamlatnyitok.raiffeisen.hu/koszonjuk.php';
  }
}
