import { Injectable } from '@angular/core';
import { UserSessionService } from './user-session.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { AppConfigService } from '../../../app-config.service';
import { BankingDayService } from '../banking-day.service';
import {TokensQueryV2Model} from '../../../model/query-models/tokens-query-v2-model';
import {CognitoTokenDto} from './cognito-token-dto';
import {retry} from 'rxjs/operators';

@Injectable()
export class CaptchaTokensService {
  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    private userSessionService: UserSessionService,
    private http: HttpClient,
    private configService: AppConfigService,
    private bankingDayService: BankingDayService
  ) {}

  public updateTokens(): void {
    const subscription = new Observable(observer =>
      this.reCaptchaV3Service.execute(
        this.configService.config.captchaSiteKey,
        'openaccount',
        (token: string) => {
          if (!!token) {
            observer.next(token);
            observer.complete();
          } else {
            this.updateTokens();
          }
        }
      )
    ).subscribe(token => {
      console.log(token, 'TOKEN=============');
      this.queryTokens(token as string).subscribe(tokens => {
        console.log('TOKENS token as string', tokens);
        if (this.userSessionService.isTokensModelValid(tokens)) {
          this.userSessionService.updateTokens = tokens;
          this.bankingDayService.checkBankingDay();
        } else {
          console.error('Empty response');
          subscription.unsubscribe();
          this.updateTokens();
        }
      });
    });
  }

  private queryTokens(reCaptchaToken: string): Observable<CognitoTokenDto> {
    const tokenModel = new TokensQueryV2Model();
    // tokenModel.tokenRequests = this.createTokenRequests();
    tokenModel.captchaResponse = reCaptchaToken;
    console.log(reCaptchaToken, 'captcha');
    const targetUrl = this.configService.config.baseUrl + '/merlin-token-svc/v1/oauth2/token';

    // const request = new QueryRequest();
    // request.query = tokenModel;
    return <Observable<CognitoTokenDto>>this.http.post(targetUrl, tokenModel).pipe(retry(5));
  }

  // private createTokenRequests(): TokenRequest[] {
  //   const tokenRequests = [
  //     {
  //       service: this.createCompanyRegistryServiceName(),
  //       scope: 'company',
  //       env: '',
  //     },
  //     {
  //       service: 'smeon-bff',
  //       scope: 'smeon-bff',
  //       env: '',
  //     },
  //     {
  //       service: 'document',
  //       scope: 'document',
  //       env: '',
  //     },
  //   ];
  //
  //   const environment = this.configService.config['environmentName'];
  //
  //   tokenRequests.forEach(tokenRequest => {
  //     tokenRequest.env = environment.toString().toLowerCase();
  //   });
  //
  //   return tokenRequests;
  // }

  // private createCompanyRegistryServiceName(): string {
  //   return !!this.configService.config.useMockCompanyRegistry &&
  //     this.configService.config.useMockCompanyRegistry === 'true'
  //     ? 'company-registry-mock'
  //     : 'company-registry';
  // }
}
