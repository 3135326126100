import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyDataComponent } from './sections/company-data/company-data.component';
import { CompanyInfoComponent } from './sections/company-info/company-info.component';
import { DebitCardComponent } from './sections/debit-card/debit-card.component';
import { AccountSelectorComponent } from './sections/account-selector/account-selector.component';
import { TrusteeDataComponent } from './sections/trustee-data/trustee-data.component';
import { StatementsContractsComponent } from './sections/statements-contracts/statements-contracts.component';
import { SectionType } from './sections/section.type';
import { IdVComponent } from './sections/id-v/id-v.component';
import { ValidationMessagesHelper } from '../../shared/validation-messages/validation-messages.helper';

const supportedSections = {
  [SectionType.CompanyData]: CompanyDataComponent,
  [SectionType.CompanyInfo]: CompanyInfoComponent,
  [SectionType.DebitCard]: DebitCardComponent,
  [SectionType.AccountSelector]: AccountSelectorComponent,
  [SectionType.TrusteeData]: TrusteeDataComponent,
  [SectionType.StatementsAndContracts]: StatementsContractsComponent,
  [SectionType.IdAndV]: IdVComponent,
} as any;

@Component({
  selector: 'app-dynamic-section-creator',
  templateUrl: './dynamic-section-creator.component.html',
})
export class DynamicSectionCreatorComponent implements OnInit, AfterViewInit {
  @Input()
  public sectionType: SectionType;

  @Input()
  public formGroup: FormGroup;

  @ViewChild('sectionContainer', { read: ViewContainerRef })
  public container: ViewContainerRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private validationMessagesHelper: ValidationMessagesHelper
  ) {}

  ngOnInit() {
    if (this.sectionType === undefined || this.sectionType === null) {
      throw Error('[Section creation error] Missing sectionType type');
    }
  }

  ngAfterViewInit(): void {
    const componentType = supportedSections[this.sectionType];
    if (componentType) {
      const factory = this.resolver.resolveComponentFactory<typeof componentType>(componentType);
      const createdComponent = this.container.createComponent(factory);
      const componentInstance = <typeof componentType>createdComponent.instance;

      componentInstance.formGroup = this.formGroup;
      componentInstance.sectionType = this.sectionType;
      componentInstance.validationMessagesHelper = this.validationMessagesHelper;

      this.changeDetector.detectChanges();
    }
  }
}
