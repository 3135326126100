import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'config' })
export class ConfigurationPipe implements PipeTransform {
  constructor(private environment: AppConfigService) {}

  transform(value: string): number {
    return this.environment.config[value];
  }
}
