import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PopupInfoDialogComponent } from './popup-info-dialog/popup-info-dialog.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input()
  public description: string;

  constructor(private dialog: MatDialog) {}

  public showInfoDialog(): void {
    this.dialog.open(PopupInfoDialogComponent, {
      width: '70%',
      data: this.description,
    });
  }
}
