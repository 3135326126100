import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationMessagesHelper } from '../../shared/validation-messages/validation-messages.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent {
  static MIN_VALID_DATE = new Date(1900, 0, 1);
  static MIN_VALID_AGE = 18;

  @Input()
  public formGroup: FormControl;

  @Input()
  public id: string;
  private currentDate = new Date();
  private pastDate = new Date(
    this.currentDate.getUTCFullYear() - UserDataComponent.MIN_VALID_AGE,
    this.currentDate.getMonth(),
    this.currentDate.getDate()
  );

  constructor(
    private validationMessagesHelper: ValidationMessagesHelper,
    private translateService: TranslateService
  ) {}

  public get adultOnly(): Date {
    return this.pastDate;
  }

  public get minValidDate(): Date {
    return UserDataComponent.MIN_VALID_DATE;
  }

  public get isDateOfBirthDisabled(): boolean {
    return this.formGroup.get('dateOfBirth').disabled;
  }

  public get hasMotherMaidenName(): boolean {
    return !!this.formGroup.get('mothersMaidenName');
  }

  public getErrorMessages(controlKey: string): string {
    const control = this.formGroup.get(controlKey) as FormControl;

    return this.validationMessagesHelper.createErrorMessages(control);
  }

  public isInvalid(controlKey: string): boolean {
    return (this.formGroup.get(controlKey) as FormControl).invalid;
  }

  public titles(): string[] {
    return ['null', 'dr', 'jr', 'sen', 'wid'].map(e =>
      this.translateService.instant('trusteeData.general.title.' + e)
    );
  }
}
