import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormGroupSideEffectCreator } from './side-effects/form-group-side-effect.creator';

@Injectable({
  providedIn: 'root',
})
export class ContactsFormGroupBuilder {
  constructor(
    private formBuilder: FormBuilder,
    private formGroupSideEffectCreator: FormGroupSideEffectCreator
  ) {}

  private _contactsFormGroup: FormGroup;

  private createContactsFormGroup(): FormGroup {
    this._contactsFormGroup = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.email]),
      phoneNumber: this.formBuilder.control('', Validators.pattern('^.{9}$')),
      companyName: this.formBuilder.control('', [Validators.required]),
      companyTaxId: this.formBuilder.control(''),
    });
    this.formGroupSideEffectCreator.addContactDataFormSideEffect(this._contactsFormGroup);

    return this._contactsFormGroup;
  }

  public getContactsFormGroup(): FormGroup {
    return !!this._contactsFormGroup ? this._contactsFormGroup : this.createContactsFormGroup();
  }
}
