import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorFocusService {
  private static matErrorsListLength = 0;

  public static focusOnFirstInvalidField(): void {
    const matErrorsList = document.getElementsByTagName('mat-error');

    if (matErrorsList.length > this.matErrorsListLength) {
      const rect = matErrorsList.item(0).getBoundingClientRect();
      const elementOffset = rect.top + window.pageYOffset - document.documentElement.clientTop;

      if (rect.top < 0) {
        window.scroll(0, elementOffset - 25);
      }
    }

    this.matErrorsListLength = matErrorsList.length;
  }
}
