import { Component } from '@angular/core';
import { CaptchaTokensService } from '../../../../shared/service/authentication/captcha-tokens.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-popup-resume-session',
  templateUrl: './popup-resume-session.component.html',
})
export class PopupResumeSessionComponent {
  constructor(
    private dialogRef: MatDialogRef<PopupResumeSessionComponent>,
    private captchaTokensService: CaptchaTokensService
  ) {}

  public onResume(): void {
    this.captchaTokensService.updateTokens();
    this.dialogRef.close();
  }
}
