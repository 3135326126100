import { CommandModel } from './command.model';

export class SendDocumentsDataNotificationV1Model extends CommandModel {
  type = 'com.rbinternational.merlin.smeon.commands.documents.SendDocumentsDataNotificationV1';
  trusteeName: string;
  transactionId: string;
  companyName: string;
  pdfId: string;
  eAktaFilesIds: string[] = [];
}
