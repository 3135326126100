import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TrusteeDataSideEffects } from './trustee-data-side-effects.service';
import { BeneficiaryDataSideEffectsService } from './beneficiary-data-side-effects.service';
import { CardDataSideEffects } from './card-data-side-effects.service';
import { StatementsDataSideEffectsService } from './statements-data-side-effects.service';
import { CompanyDataSideEffectsService } from './company-data-side-effects.service';
import { CompanyInfoSideEffectsService } from './company-info-side-effects.service';

@Injectable()
export class FormGroupSideEffectCreator {
  constructor(
    private trusteeDataSideEffects: TrusteeDataSideEffects,
    private beneficiaryDataSideEffectsService: BeneficiaryDataSideEffectsService,
    private cardDataSideEffects: CardDataSideEffects,
    private statementDataSideEffectsService: StatementsDataSideEffectsService,
    private companyDataSideEffectsService: CompanyDataSideEffectsService,
    private companyInfoSideEffectsService: CompanyInfoSideEffectsService
  ) {}

  public addSideEffect(form: FormGroup): void {
    this.trusteeDataSideEffects.addSideEffect(form);
    this.beneficiaryDataSideEffectsService.addSideEffect(form);
    this.cardDataSideEffects.addSideEffect(form);
    this.statementDataSideEffectsService.addSideEffect(form);
    this.companyDataSideEffectsService.addSideEffect(form);
    this.companyInfoSideEffectsService.addSideEffect(form);
  }

  public addAcceptanceFormSideEffect(acceptanceForm: FormGroup): void {
    this.statementDataSideEffectsService.addAcceptanceFormSideEffect(acceptanceForm);
  }

  public addContactDataFormSideEffect(contactDataForm: FormGroup): void {
    this.trusteeDataSideEffects.addContactDataFormSideEffect(contactDataForm);
  }
}
