import { FormControl, FormGroup } from '@angular/forms';
import { SectionType } from './section.type';
import { ValidationMessagesHelper } from '../../../shared/validation-messages/validation-messages.helper';
import { ErrorFocusService } from '../../../shared/service/error-focus.service';

export abstract class SectionComponent {
  public formGroup: FormGroup;
  public sectionType: SectionType;
  public validationMessagesHelper: ValidationMessagesHelper;

  public get sectionFormGroup(): FormGroup {
    return this.formGroup.get(this.sectionType) as FormGroup;
  }

  public get isPrivateEntrepreneur(): boolean {
    const companyType = this.formGroup.get('companyInfo.companyType').value;
    return companyType === 'privateEntrepreneur';
  }

  public extractFormGroup(key: string): FormGroup {
    return this.sectionFormGroup.get(key) as FormGroup;
  }

  public getErrorMessages(controlKey: string): string {
    const control = this.sectionFormGroup.get(controlKey) as FormControl;

    return this.validationMessagesHelper.createErrorMessages(control);
  }

  public isInvalid(controlKey: string): boolean {
    const isInvalid = (this.sectionFormGroup.get(controlKey) as FormControl).invalid;
    if (isInvalid) {
      ErrorFocusService.focusOnFirstInvalidField();
    }
    return isInvalid;
  }

  public landLinePhoneMask(controlKey: string): string {
    const controlValue = this.extractFormGroup(controlKey).value;
    return String(controlValue).startsWith('1') ? '0 000 00999'  : '00 000 0999'  ;
  }

  public mobilePhoneMask(): string {
    return '00 0000000';
  }

  public get companyVatNumberMask(): string {
    return '00000000-0-00';
  }

  public get userTaxIdMask(): string {
    return '0000000000';
  }

  public getFormControlsValidationStatus(controls: string[], form: FormGroup): string {
    const isTouched = controls
      .map(controlName => form.get(controlName))
      .map(control => control.touched)
      .reduce((p1, p2) => p1 || p2, false);

    const isFormHasInvalidControl = controls
      .map(controlName => form.get(controlName))
      .map(control => control.invalid)
      .reduce((p1, p2) => p1 || p2, false);

    return isFormHasInvalidControl ? (isTouched ? 'invalid' : '') : 'valid';
  }

  public getSectionControlsValidationStatus(controls: string[]): string {
    return this.getFormControlsValidationStatus(controls, this.sectionFormGroup);
  }

  public get isManualMode(): boolean {
    return this.formGroup.get('companyInfo.companyFetched').value === 'manual';
  }
}
