import { Injectable } from '@angular/core';
import { SmeonBffService } from './client/smeon-bff.service';
import { Observable } from 'rxjs';
import { SendDocumentsDataNotificationV1Model } from '../../model/command-models/send-documents-data-notification-v1.model';
import { CommandRequestModel } from '../../model/command-models/command-request.model';
import { FormGroup } from '@angular/forms';
import { TransactionIdService } from './transaction-id.service';
import { DocumentsIdsHelper } from '../files-id-helper/documents-ids-helper.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentDataSendingService {
  constructor(
    private bffService: SmeonBffService,
    private transactionIdService: TransactionIdService,
    private documentsIdsHelper: DocumentsIdsHelper
  ) {}

  public sendDocumentDataToCCAgent(form: FormGroup): Observable<boolean> {
    const sendDocumentsDataModel = new SendDocumentsDataNotificationV1Model();
    sendDocumentsDataModel.trusteeName = form.get('trusteeData.general.fullName').value;
    sendDocumentsDataModel.companyName = form.get('companyData.companyDetails.companyName').value;
    sendDocumentsDataModel.transactionId = this.transactionIdService.transactionId;
    sendDocumentsDataModel.eAktaFilesIds = this.documentsIdsHelper.getEAktaFilesIds(form);
    sendDocumentsDataModel.pdfId = this.documentsIdsHelper.getPdfFileId();

    const request = new CommandRequestModel();
    request.commands.push(sendDocumentsDataModel);

    return this.bffService.command(request);
  }
}
