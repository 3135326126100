import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SectionType } from '../../../components/dynamic-section-creator/sections/section.type';
import { TransactionIdService } from '../../service/transaction-id.service';

@Injectable()
export class StatementsDataSideEffectsService implements OnDestroy {
  private form: FormGroup;
  private sectionForm: FormGroup;
  private acceptanceForm: FormGroup;
  private additionalBeneficiaryOwnerFormArray: FormArray;

  private beneficiaryOwnershipsRateSubscription: Subscription;
  private additionalBeneficiaryOwnershipListChangesSubscriptions: Subscription;
  private additionalBeneficiaryOwnershipsRateSubscriptions: Subscription[] = [];
  private formChangesSubscriptions: Subscription;
  private statementChangesSubscriptions: Subscription;
  private companyTypeChangesSubscription: Subscription;
  private typeOfOwnershipChangesSubscription: Subscription;
  private additionalBeneficiaryOwnershipsTypeSubscriptions: Subscription[] = [];

  constructor(private transactionIdService: TransactionIdService) {}

  public ngOnDestroy(): void {
    if (this.typeOfOwnershipChangesSubscription) {
      this.typeOfOwnershipChangesSubscription.unsubscribe();
    }
    if (this.beneficiaryOwnershipsRateSubscription) {
      this.beneficiaryOwnershipsRateSubscription.unsubscribe();
    }
    if (this.additionalBeneficiaryOwnershipListChangesSubscriptions) {
      this.additionalBeneficiaryOwnershipListChangesSubscriptions.unsubscribe();
    }
    if (this.companyTypeChangesSubscription) {
      this.companyTypeChangesSubscription.unsubscribe();
    }

    this.unsubscribeFormArrayChanges();
  }

  public addSideEffect(form: FormGroup): void {
    this.form = form;
    this.sectionForm = <FormGroup>form.get(SectionType.StatementsAndContracts);

    this.additionalBeneficiaryOwnershipListChangesSubscriptions = this.createAdditionalBeneficiaryOwnershipListSubscriptions();
    this.beneficiaryOwnershipsRateSubscription = this.createBeneficiaryOwnershipsRateSubscription();
    this.companyTypeChangesSubscription = this.createCompanyTypeSubscription();
  }

  public addAcceptanceFormSideEffect(acceptanceForm: FormGroup) {
    this.acceptanceForm = acceptanceForm;
    this.statementChangesSubscriptions = this.createAcceptanceChangesSubscriptions();
  }

  private createAdditionalBeneficiaryOwnershipListSubscriptions(): Subscription {
    this.additionalBeneficiaryOwnerFormArray = <FormArray>(
      this.sectionForm.get('additionalBeneficiaryOwner')
    );

    return this.additionalBeneficiaryOwnerFormArray.valueChanges.subscribe(() => {
      this.isRatesSumInvalid() ? this.addSumTooBigErrors() : this.removeSumTooBigErrors();
      this.subscribeToFormArrayControlsChanges();
    });
  }

  private unsubscribeFormArrayChanges(): void {
    this.additionalBeneficiaryOwnershipsRateSubscriptions.forEach(subscription => {
      if (!!subscription) {
        subscription.unsubscribe();
      }
    });
    this.additionalBeneficiaryOwnershipsTypeSubscriptions.forEach(subscription => {
      if (!!subscription) {
        subscription.unsubscribe();
      }
    });
    this.additionalBeneficiaryOwnershipsRateSubscriptions = [];
    this.additionalBeneficiaryOwnershipsTypeSubscriptions = [];
  }

  private subscribeToFormArrayControlsChanges(): void {
    this.unsubscribeFormArrayChanges();

    this.additionalBeneficiaryOwnerFormArray.controls.forEach(formGroup => {
      const ownershipFormGroup = <FormGroup>formGroup.get('ownership');
      const ownershipRateFormControl = <FormControl>ownershipFormGroup.get('ownershipRate');

      const ownershipRateValueChangesSubscription = ownershipRateFormControl.valueChanges.subscribe(
        () => {
          this.isRatesSumInvalid() ? this.addSumTooBigErrors() : this.removeSumTooBigErrors();
          this.isRateRatioAndInvalid(ownershipFormGroup)
            ? this.addRateNeedToBeHigherError(ownershipRateFormControl)
            : this.removeNeedToBeHigherError(ownershipRateFormControl);
        }
      );

      this.additionalBeneficiaryOwnershipsRateSubscriptions.push(
        ownershipRateValueChangesSubscription
      );

      const ownershipTypeValueChangesSubscription = ownershipFormGroup
        .get('typeOfOwnership')
        .valueChanges.subscribe(value => {
          if (value === 'senior-officer') {
            ownershipRateFormControl.disable();
            ownershipRateFormControl.reset();
          } else {
            ownershipRateFormControl.enable();
          }
        });

      this.additionalBeneficiaryOwnershipsTypeSubscriptions.push(
        ownershipTypeValueChangesSubscription
      );
    });
  }

  private createBeneficiaryOwnershipsRateSubscription(): Subscription {
    const ownershipFormGroup = <FormGroup>this.sectionForm.get('beneficiaryOwner.ownership');
    const ownershipRateControl = <FormControl>ownershipFormGroup.get('ownershipRate');

    return ownershipFormGroup.valueChanges.subscribe(() => {
      this.isRatesSumInvalid() ? this.addSumTooBigErrors() : this.removeSumTooBigErrors();
      this.isRateRatioAndInvalid(ownershipFormGroup)
        ? this.addRateNeedToBeHigherError(ownershipRateControl)
        : this.removeNeedToBeHigherError(ownershipRateControl);
    });
  }

  private isRatesSumInvalid(): boolean {
    const parseFloat = (value: string) => {
      const parsedNumber = Number.parseFloat(value);
      return parsedNumber ? parsedNumber : 0;
    };

    let rateSum = parseFloat(
      this.sectionForm.get('beneficiaryOwner.ownership.ownershipRate').value
    );

    this.additionalBeneficiaryOwnerFormArray.controls.forEach(formGroup => {
      rateSum = rateSum + parseFloat(formGroup.get('ownership.ownershipRate').value);
    });
    return rateSum > 100;
  }

  private addSumTooBigErrors(): void {
    const addError = (errors: ValidationErrors) => {
      errors ? (errors.rateSumTooBig = true) : (errors = { rateSumTooBig: true });
      return errors;
    };

    const ownershipRateErrors = this.sectionForm.get('beneficiaryOwner.ownership.ownershipRate')
      .errors;

    this.sectionForm
      .get('beneficiaryOwner.ownership.ownershipRate')
      .setErrors(addError(ownershipRateErrors));

    this.additionalBeneficiaryOwnerFormArray.controls.forEach(formGroup => {
      const errors = formGroup.get('ownership.ownershipRate').errors;
      formGroup.get('ownership.ownershipRate').setErrors(addError(errors));
    });
  }

  private removeSumTooBigErrors(): void {
    const ownershipRateErrors = this.sectionForm.get('beneficiaryOwner.ownership.ownershipRate')
      .errors;

    if (ownershipRateErrors) {
      delete ownershipRateErrors['rateSumTooBig'];
    }

    this.sectionForm.get('beneficiaryOwner.ownership.ownershipRate').setErrors(ownershipRateErrors);

    this.additionalBeneficiaryOwnerFormArray.controls.forEach(formGroup => {
      const errors = formGroup.get('ownership.ownershipRate').errors;

      if (errors) {
        delete errors['rateSumTooBig'];
      }

      formGroup.get('ownership.ownershipRate').setErrors(errors);
    });
  }

  private addRateNeedToBeHigherError(formControl: FormControl): void {
    const addError = (errors: ValidationErrors) => {
      errors ? (formControl.errors.rateNeedBeHigher = true) : (errors = { rateNeedBeHigher: true });
      return errors;
    };

    formControl.setErrors(addError(formControl.errors));
  }

  private removeNeedToBeHigherError(formControl: FormControl): void {
    const errors = formControl.errors;

    if (errors) {
      delete errors['rateNeedBeHigher'];
    }
    formControl.setErrors(errors);
  }

  private isRateRatioAndInvalid(ownershipFormGroup: FormGroup) {
    const ownershipRate = ownershipFormGroup.get('ownershipRate').value;
    const typeOfOwnership = ownershipFormGroup.get('typeOfOwnership').value;
    return typeOfOwnership === 'ratio' && ownershipRate && ownershipRate < 25;
  }

  private createAcceptanceChangesSubscriptions(): Subscription {
    return this.acceptanceForm.get('isLastPdfDownloaded').valueChanges.subscribe(value => {
      const subscribeToFormChanges = () => {
        this.formChangesSubscriptions = this.createFormChangesSubscriptions();
      };

      const unSubscribeToFormChanges = () => {
        if (this.formChangesSubscriptions) {
          this.formChangesSubscriptions.unsubscribe();
        }
      };

      value ? subscribeToFormChanges() : unSubscribeToFormChanges();
    });
  }

  private createFormChangesSubscriptions(): Subscription {
    return this.form.valueChanges.subscribe(() => {
      const isLastPdfDownloaded = this.acceptanceForm.get('isLastPdfDownloaded');
      isLastPdfDownloaded.reset();
      isLastPdfDownloaded.markAsTouched();
      const isAgreedDataStoring = this.acceptanceForm.get('isAgreedDataStoring');
      isAgreedDataStoring.reset();
      isAgreedDataStoring.disable();
      this.transactionIdService.resetTransactionId();
    });
  }

  private createTypeOfOwnershipChangesSubscription(): Subscription {
    const ownershipFormGroup = this.form.get('statements.beneficiaryOwner.ownership');
    const ownershipRateFormControl = ownershipFormGroup.get('ownershipRate');
    return ownershipFormGroup.get('typeOfOwnership').valueChanges.subscribe(value => {
      if (value === 'senior-officer') {
        ownershipRateFormControl.disable();
        ownershipRateFormControl.reset();
      } else {
        ownershipRateFormControl.enable();
      }
    });
  }

  private createCompanyTypeSubscription(): Subscription {
    return this.form.get('companyInfo.companyType').valueChanges.subscribe(value => {
      if (value === 'privateEntrepreneur') {
        this.unsubscribeTypeOfOwnershipChangesSubscription();
      } else {
        this.typeOfOwnershipChangesSubscription = this.createTypeOfOwnershipChangesSubscription();
      }
    });
  }

  private unsubscribeTypeOfOwnershipChangesSubscription(): void {
    if (!!this.typeOfOwnershipChangesSubscription) {
      this.typeOfOwnershipChangesSubscription.unsubscribe();
    }
  }
}
