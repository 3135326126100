import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserSessionService } from '../authentication/user-session.service';
import { AppConfigService } from '../../../app-config.service';
import { retry } from 'rxjs/operators';

@Injectable()
export class CompanyRegistryService {
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private userSessionService: UserSessionService
  ) {}

  public getCompanyRegistry(vatNumber: string, authorisedPersonTaxId?: string): Observable<any> {
    console.log("Method called");
    const url = this.appConfigService.config.baseUrl + this.createCompanyRegistryServiceEndpoint();
    console.log("URL-i  =>>> " + url);

    const headers = {
      ...this.userSessionService.authorizationCompanyRegistryHeader,
      'Content-Type': 'application/json',
    };

    const params = {
      countryCode: 'hu',
      vatNumber: vatNumber,
    };

    if (authorisedPersonTaxId) {
      params['authorisedPersonTaxId'] = authorisedPersonTaxId;
    }

    return this.http.get(url, { headers: headers, params: params }).pipe(
      retry(3) // retry a failed request up to 3 times
    );
  }

  private createCompanyRegistryServiceEndpoint(): string {
    return !!this.appConfigService.config.useMockCompanyRegistry &&
      this.appConfigService.config.useMockCompanyRegistry === 'true'
      ? '/merlin-company-registry-mock-svc/v1/company'
      : '/merlin-company-registry-svc/v1/company';
  }
}
