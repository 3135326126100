import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SmeonBffService } from './client/smeon-bff.service';
import { FormGroup } from '@angular/forms';
import { GeneratePdfCommandV1Model } from '../../model/command-models/generate-pdf-command-v1.model';
import { GenerateMetadatafileCommandV1Model } from '../../model/command-models/generate-metadatafile-command-v1.model';
import { CommandRequestModel } from '../../model/command-models/command-request.model';
import { PdfModel } from '../../model/pdf.model';
import { PdfQueryV1Model } from '../../model/query-models/pdf-query-v1.model';
import { DocumentsIdsHelper } from '../files-id-helper/documents-ids-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FormPostService {
  constructor(
    private smeonBffService: SmeonBffService,
    private documentsIdsHelper: DocumentsIdsHelper
  ) {}

  public openPostedForm(form: FormGroup): Observable<boolean> {
    const openPdf = data => {
      console.log('FIRST LOG DATA ---->' , data);
      const binary_string = window.atob(data);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      const file = new Blob([bytes], { type: 'application/pdf' });
      console.log('FILEEEEE ---------->', file);
      const fileURL = URL.createObjectURL(file);
      console.log('SECOND LOG FILE URL ----------->' , fileURL);
      window.open(fileURL);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'Raiffeisen_Online_Számlanyitás.pdf';
      link.click();
    };

    return new Observable(subscriber => {
      this.postForm(form).subscribe(result => {
        console.log('THIRD LOG');
        if (!!result) {
          console.log('FOURTH LOG');
          this.downloadPdf().subscribe(pdf => {
            console.log('FIFTH LOG');
            openPdf(pdf.content);
            subscriber.next(true);
          });
        } else {
          console.log('SIXTH LOG');
          subscriber.next(false);
        }
      });
    });
  }

  private postForm(form: FormGroup): Observable<boolean> {
    const metadatafileCommandV1Model = new GenerateMetadatafileCommandV1Model();

    const isPrivateEntrepreneur = form.get('companyInfo.companyType').value === 'privateEntrepreneur';

    metadatafileCommandV1Model.metadata = JSON.stringify({
      individual: isPrivateEntrepreneur,
      companyName: form.get('companyData.companyDetails.companyName').value,
      companyTaxId: form.get('companyInfo.companyTaxId').value,
      companyRegistrationNumber: form.get('companyData.companyDetails.companyRegistrationNumber')
        .value,
    });

    metadatafileCommandV1Model.documentId = this.documentsIdsHelper.getMetadataFileId();
    console.log('SEVENTH LOG documentId ------------->' , metadatafileCommandV1Model.documentId);

    const pdfCommandV1Model = new GeneratePdfCommandV1Model();

    const formData = form.getRawValue();

    pdfCommandV1Model.formJson = JSON.stringify(
      this.infectGeneratedTime(formData),
      this.replacer,
      2
    );
    pdfCommandV1Model.documentId = this.documentsIdsHelper.getPdfFileId();
    console.log(' 8TH LOG pdf document id ------------>' , pdfCommandV1Model.documentId);

    const request = new CommandRequestModel();
    request.commands.push(metadatafileCommandV1Model);
    request.commands.push(pdfCommandV1Model);
    return this.smeonBffService.command(request);
  }

  private downloadPdf(): Observable<PdfModel> {

    console.log('SA HER PO THIRRESH BOSS???');
    const pdfQueryModel = new PdfQueryV1Model();
    pdfQueryModel.documentId = this.documentsIdsHelper.getPdfFileId();
    sessionStorage.setItem('documentId', pdfQueryModel.documentId);
    return this.smeonBffService.query<PdfModel>(pdfQueryModel);
  }

  private replacer(key, value) {
    console.log('replacer ------------------- replacer------------');
    const fileFields = [
      'applicationOfElectronicRegistration',
      'certificateOfIncorporation',
      'electronicCheckReceipt',
      'certificate',
    ];
    const isFile = fileFields.includes(key);

    const dateFields = [
      'dateOfBirth',
      'IDCardValidFrom',
      'IDCardValidUntil',
      'addressCardValidFrom',
    ];
    const isDate = dateFields.includes(key);

    return isFile ? undefined : isDate ? new Date(value).toLocaleDateString('hu-HU') : value;
  }

  private infectGeneratedTime(formGroupRawValues: {}): {} {
    console.log('INFECT GENERATED TIME');
    formGroupRawValues['generatedTime'] = new Date().toLocaleString('hu-HU');

    return formGroupRawValues;
  }
}
