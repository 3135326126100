export class VerificationAddress {
  constructor(data: any) {
    Object.assign(this, data);
  }

  public country: string;

  public city: string;

  public zipCode: string;

  public street: string;

  public streetNumber: string;
}
