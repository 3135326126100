import { v4 as uuid } from 'uuid';
import { CommandModel } from './command.model';

export class CommandRequestModel {
  requestId: string = uuid();

  sessionId: string = uuid();

  commands: CommandModel[] = [];
}
