import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormGroupSideEffectCreator } from './side-effects/form-group-side-effect.creator';

export function maxLengthValidation(maxLength: number): ValidatorFn {
  return Validators.pattern('^.{0,' + maxLength + '}$');
}

@Injectable({
  providedIn: 'root',
})
export class FormGroupBuilder {
  constructor(
    private formBuilder: FormBuilder,
    private formSideEffectService: FormGroupSideEffectCreator
  ) {}

  private landLinePhoneNumberPattern = '^.{8,9}$';
  private mobilePhoneNumberPattern = '^.{9}$';

  public create(): FormGroup {
    const form = this.formBuilder.group({
      applicationId: this.formBuilder.control(''),
      companyInfo: this.createCompanyInfoForm(),
      accountSelector: this.createProductSelectorForm(),
      companyData: this.createCompanyDataForm(),
      trusteeData: this.createTrusteeDataForm(),
      debitCard: this.createDebitCardForm(),
      statements: this.createStatementsForm(),
      verification: this.createVerificationForm(),
    });

    this.formSideEffectService.addSideEffect(form);

    return form;
  }

  public createBeneficiaryForm(): FormGroup {
    const userForm = this.createUserForm();
    userForm.addControl(
      'ownership',
      this.formBuilder.group({
        typeOfOwnership: this.formBuilder.control('', [Validators.required]),
        ownershipRate: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern('(^[0-9]*)\\.?([0-9]*)'),
          Validators.compose([Validators.min(0.00000000001), Validators.max(100)]),
        ]),
      })
    );
    userForm.addControl(
      'taxResidency',
      this.formBuilder.group({
        isTaxResidencyCountryHungary: this.formBuilder.control(false, [Validators.required]),
        isFatcaAccepted: this.formBuilder.control(false, [Validators.required]),
      })
    );
    userForm.addControl('actorType', this.formBuilder.control(''));
    userForm.removeControl('mailing');

    return userForm;
  }

  public createAdditionalBeneficiaryForm(): FormGroup {
    const additionalBeneficiaryForm = this.createBeneficiaryForm();
    additionalBeneficiaryForm.get('contact.primaryEmail').setValidators(null);
    additionalBeneficiaryForm.get('contact.primaryMobileNumber').setValidators(null);

    return additionalBeneficiaryForm;
  }

  private createCompanyInfoForm(): FormGroup {
    return this.formBuilder.group({
      isPrivacyStatementAccepted: this.formBuilder.control(false, [Validators.requiredTrue]),
      companyFetched: this.formBuilder.control('', [Validators.required]),
      companyType: this.formBuilder.control('', [Validators.required]),
      companyTaxId: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern('^[0-9]{11}$'),
      ]),
      files: this.formBuilder.group({
        applicationOfElectronicRegistration: this.formBuilder.control('', [Validators.required]),
        certificateOfIncorporation: this.formBuilder.control('', [Validators.required]),
        electronicCheckReceipt: this.formBuilder.control('', [Validators.required]),
        certificate: this.formBuilder.control('', [Validators.required]),
        articlesOfAssociation: this.formBuilder.control('', [Validators.required]),
      }),
      documents: this.formBuilder.group({
        applicationOfElectronicRegistrationId: this.formBuilder.control(''),
        certificateOfIncorporationId: this.formBuilder.control(''),
        electronicCheckReceiptId: this.formBuilder.control(''),
        certificateId: this.formBuilder.control(''),
        articlesOfAssociationId: this.formBuilder.control(''),
      }),
      userTaxId: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$'),
      ]),
      isBookKeeper: this.formBuilder.control(false),
    });
  }

  private createCompanyDataForm(): FormGroup {
    const primaryEmail = this.formBuilder.control('', [
      Validators.required,
      Validators.email,
      maxLengthValidation(40),
    ]);

    return this.formBuilder.group({
      companyDetails: this.formBuilder.group({
        companyName: this.formBuilder.control('', [Validators.required]),
        companyRegistrationNumber: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern('^[0-9]{10}$'),
        ]),
        vatNumber: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern('^[0-9]{11}$'),
        ]),
        naceCode: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern('^[0-9]{6}$'),
        ]),
      }),
      isFinancialInstitution: this.formBuilder.control(false),
      fatcaDisclosure: this.formBuilder.control('', [Validators.required]),
      status: this.formBuilder.control(''),
      statusText: this.formBuilder.control(''),
      providedTaxNumStatus: this.formBuilder.control(''),
      seat: this.createAddressForm(),
      addressSameAsSeat: this.formBuilder.control(false),
      address: this.createAddressForm(),
      contactDetails: this.formBuilder.group({
        primaryEmail: primaryEmail,
        primaryEmailConfirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.email,
          this.fieldValuesMatch(primaryEmail),
        ]),
        primaryLandLineNumber: this.formBuilder.control('', [
          Validators.pattern(this.landLinePhoneNumberPattern),
        ]),
        primaryMobileNumber: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(this.mobilePhoneNumberPattern),
        ]),
        additionalMobileNumber1: this.formBuilder.control(
          '',
          Validators.pattern(this.mobilePhoneNumberPattern)
        ),
        additionalMobileNumber2: this.formBuilder.control(
          '',
          Validators.pattern(this.mobilePhoneNumberPattern)
        ),
        correspondenceLanguage: this.formBuilder.control('hu', [Validators.required]),
      }),
      isTaxResidencyCountryHungary: this.formBuilder.control(false, [Validators.requiredTrue]),
      accountDetails: this.formBuilder.group({
        accountPackage: this.formBuilder.control('', [Validators.required]),
        accountStatementType: this.formBuilder.control('in-branch', [
          Validators.required,
          maxLengthValidation(30),
        ]),
        accountStatementFrequency: this.formBuilder.control('monthly', [
          Validators.required,
          maxLengthValidation(30),
        ]),
      }),
    });
  }

  private fieldValuesMatch(source: FormControl): ValidatorFn {
    let subscription;
    return (control: AbstractControl): ValidationErrors | null => {
      if (!subscription) {
        subscription = source.valueChanges.subscribe(() => {
          control.updateValueAndValidity();
        });
      }
      return source.value !== control.value ? { emailDoesNotMatch: 'emailDoesNotMatch' } : null;
    };
  }

  private integer(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return new RegExp('^[0-9]+$').test(control.value) ? null : { number: 'number' };
    };
  }

  private createDebitCardForm(): FormGroup {
    return this.formBuilder.group({
      city: this.formBuilder.control(''),
      address: this.formBuilder.control(''),
      nameOnTheCard: this.formBuilder.control('', [Validators.required, Validators.maxLength(25)]),
      companyName: this.formBuilder.control('', [Validators.required, Validators.maxLength(25)]),
      purchaseLimit: this.formBuilder.control('500000', [
        Validators.required,
        Validators.max(9999999999999),
        Validators.min(0),
        this.integer(),
      ]),
      maxPurchases: this.formBuilder.control('15', [
        Validators.required,
        Validators.max(15),
        Validators.min(1),
        this.integer(),
      ]),
      advanceLimit: this.formBuilder.control('300000', [
        Validators.required,
        Validators.max(300000),
        Validators.min(0),
        this.integer(),
      ]),
      maxAdvances: this.formBuilder.control('5', [
        Validators.required,
        Validators.max(5),
        Validators.min(1),
        this.integer(),
      ]),
      delivery: this.formBuilder.control('', [Validators.required]),
    });
  }

  private createTrusteeDataForm(): FormGroup {
    const userForm = this.createUserForm();

    (<FormGroup>userForm.get('general')).addControl(
      'mothersMaidenName',
      this.formBuilder.control('', [Validators.required, maxLengthValidation(45)])
    );
    userForm.addControl('permanentSameAsSeat', this.formBuilder.control(false));
    userForm.addControl('mailingSameAsPermanent', this.formBuilder.control(false));
    userForm.addControl('primaryEmailSameAsCompanyEmail', this.formBuilder.control(false));
    userForm.addControl('primaryMobileNumberSameAsCompanyMobile', this.formBuilder.control(false));
    userForm.addControl(
      'landLinePhoneNumberSameAsCompanyLandLine',
      this.formBuilder.control(false)
    );

    userForm.addControl(
      'documents',
      this.formBuilder.group({
        IDCardType: this.formBuilder.control(''),
        IDCardNumber: this.formBuilder.control(''),
        IDCardValidFrom: this.formBuilder.control(''),
        IDCardValidUntil: this.formBuilder.control(''),
        addressCardNumber: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern('^\\d\\d\\d\\d\\d\\d\\D\\D$'),
        ]),
        addressCardValidFrom: this.formBuilder.control(''),
      })
    );

    return userForm;
  }

  private createUserForm() {
    return this.formBuilder.group({
      general: this.formBuilder.group({
        gender: this.formBuilder.control('', [Validators.required]),
        title: this.formBuilder.control(''),
        fullName: this.formBuilder.control('', [Validators.required, maxLengthValidation(45)]),
        birthFullName: this.formBuilder.control('', [Validators.required, maxLengthValidation(45)]),
        dateOfBirth: this.formBuilder.control('', [Validators.required]),
        cityOfBirth: this.formBuilder.control('', [Validators.required, maxLengthValidation(20)]),
        countryOfBirth: this.formBuilder.control('hu', [Validators.required]),
        countryOfBirthOther: this.formBuilder.control('', [Validators.required]),
        nationality: this.formBuilder.control('hu', [Validators.required]),
        representationType: this.formBuilder.control(''),
      }),
      address: this.createAddressForm(),
      mailing: this.createAddressForm(),
      contact: this.formBuilder.group({
        primaryEmail: this.formBuilder.control('', [Validators.required, Validators.email]),
        primaryMobileNumber: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(this.mobilePhoneNumberPattern),
        ]),
        landLinePhoneNumber: this.formBuilder.control('', [
          Validators.pattern(this.landLinePhoneNumberPattern),
        ]),
        additionalMobileNumber: this.formBuilder.control('', [
          Validators.pattern(this.mobilePhoneNumberPattern),
        ]),
        correspondenceLanguage: this.formBuilder.control('hu'),
      }),
    });
  }

  private createProductSelectorForm(): FormGroup {
    return this.formBuilder.group({
      selectedPackage: this.formBuilder.control('', [Validators.required]),
    });
  }

  private createStatementsForm(): FormGroup {
    return this.formBuilder.group({
      beneficiaryOwner: this.createBeneficiaryForm(),
      additionalBeneficiaryOwner: this.formBuilder.array([]),
      sameAsUser: this.formBuilder.control('', []),
    });
  }

  private createVerificationForm(): FormGroup {
    return this.formBuilder.group({});
  }

  private createAddressForm(): FormGroup {
    return this.formBuilder.group({
      addressField: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100),
      ]),
    });
  }
}
