import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserSessionService } from '../authentication/user-session.service';
import { QueryModel } from '../../../model/query-models/query.model';
import { AppConfigService } from '../../../app-config.service';
import { QueryRequest } from '../../../model/query-models/query-request.model';
import { CommandRequestModel } from '../../../model/command-models/command-request.model';

@Injectable()
export class SmeonBffService {
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private userSessionService: UserSessionService
  ) {}

  public query<T>(body: QueryModel): Observable<T> {
    const targetUrl = this.appConfigService.config.baseUrl + '/merlin-smeon-bff-svc/v1/query';

    const request = new QueryRequest();
    request.query = body;
    return <Observable<T>>this.http.post(targetUrl, request, {
      headers: this.userSessionService.authorizationBffHeader,
    });
  }

  public command(request: CommandRequestModel): Observable<boolean> {
    const targetUrl = this.appConfigService.config.baseUrl + '/merlin-smeon-bff-svc/v1/commands';

    return <Observable<boolean>>this.http.post(targetUrl, request, {
      headers: this.userSessionService.authorizationBffHeader,
    });
  }
}
