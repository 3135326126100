import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { WorkingTimeService } from '../../../../shared/service/working-time.service';
import { AppConfigService } from '../../../../app-config.service';
import { BeforeUnloadService } from '../../../../shared/service/before-unload.service';
import { BankingDayService } from '../../../../shared/service/banking-day.service';

@Component({
  selector: 'app-popup-working-time-dialog',
  templateUrl: './popup-working-time-dialog.component.html',
})
export class PopupWorkingTimeDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PopupWorkingTimeDialogComponent>,
    private workingTimeService: WorkingTimeService,
    private configService: AppConfigService,
    private beforeUnloadService: BeforeUnloadService,
    private bankingDayService: BankingDayService
  ) {}

  public get isRemindingTime(): boolean {
    return this.workingTimeService.isRemindingTime;
  }

  public get isBankingDay(): boolean {
    return this.bankingDayService.isBankingDay;
  }

  public onNoClick(): void {
    if (this.isRemindingTime) {
      this.dialogRef.close();
    } else {
      this.beforeUnloadService.removeEventListener();
      window.open(this.configService.config['rbhuWelcomePage'], '_self');
    }
  }
}
