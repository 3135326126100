import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {CountlyService} from './shared/service/countly.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  private langParamsSubscription: Subscription;

  constructor(private translate: TranslateService, private route: ActivatedRoute, private countlyService: CountlyService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('hu');
    this.countlyService.initCountly();
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('hu');
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.langParamsSubscription = this.route.queryParams.subscribe(data => {
      if (!!data && !!data['lang']) {
        this.translate.use(data['lang']);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.langParamsSubscription) {
      this.langParamsSubscription.unsubscribe();
    }
  }
}
