import { FileType } from '../../../file-upload/file.type';

const applicationOfElectronicRegistration: CompanyFile = {
  fileTypes: [FileType.ES3],
  fileName: 'applicationOfElectronicRegistration',
};
const electronicCheckReceipt: CompanyFile = {
  fileTypes: [FileType.ET3],
  fileName: 'electronicCheckReceipt',
};
const certificate: CompanyFile = {
  fileTypes: [FileType.ODT, FileType.ES3],
  fileName: 'certificate',
};
const articlesOfAssociation: CompanyFile = {
  fileTypes: [
    FileType.ES3,
    FileType.PDF,
    FileType.JPEG,
    FileType.JPG,
    FileType.TIF,
    FileType.PNG,
    FileType.TIFF,
    FileType.BMP,
  ],
  fileName: 'articlesOfAssociation',
};

export const companyTypeFilesMatching = {
  privateEntrepreneur: [],
  limitedLiability: [articlesOfAssociation],
  newCompany: [
    articlesOfAssociation,
    applicationOfElectronicRegistration,
    electronicCheckReceipt,
    certificate,
  ],
  individualCompany: [articlesOfAssociation],
  cooperative: [articlesOfAssociation],
  generalPartnership: [articlesOfAssociation],
};

export interface CompanyFile {
  fileTypes: FileType[];
  fileName: string;
}
