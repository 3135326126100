import { Injectable } from '@angular/core';
import { TransactionIdService } from '../service/transaction-id.service';
import { FormGroup } from '@angular/forms';
import { SectionType } from '../../components/dynamic-section-creator/sections/section.type';

@Injectable()
export class DocumentsIdsHelper {
  constructor(public transactionIdService: TransactionIdService) {}

  public getEAktaFilesIds(form: FormGroup): string[] {
    const documentsFormGroupControls = (<FormGroup>(
      form.get(SectionType.CompanyInfo).get('documents')
    )).controls;

    return Object.keys(documentsFormGroupControls)
      .map(key => documentsFormGroupControls[key].value)
      .filter(value => !!value);
  }

  public getPdfFileId(): string {
    return this.transactionIdService.transactionId + '.pdf';
  }

  public getMetadataFileId(): string {
    return this.transactionIdService.transactionId + '.json';
  }
}
