import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable()
export class CompanyInfoSideEffectsService implements OnDestroy {
  private form: FormGroup;
  private filesChangesSubscriptions: Subscription;
  private contactDataFormChangesSubscriptions: Subscription;

  public addSideEffect(form: FormGroup): void {
    this.form = form;
    this.filesChangesSubscriptions = this.createFilesChangesSubscriptions();
  }

  public ngOnDestroy(): void {
    if (!!this.filesChangesSubscriptions) {
      this.filesChangesSubscriptions.unsubscribe();
    }
    if (!!this.contactDataFormChangesSubscriptions) {
      this.contactDataFormChangesSubscriptions.unsubscribe();
    }
  }

  private createFilesChangesSubscriptions(): Subscription {
    const documentsFormGroup = this.form.get('companyInfo.documents');
    const companyInfoFiles = <FormGroup>this.form.get('companyInfo.files');

    const extractFileExtension = (file: File) => {
      return file ? file.name.substring(file.name.lastIndexOf('.'), file.name.length) : '';
    };

    return companyInfoFiles.valueChanges.subscribe(() => {
      Object.keys(companyInfoFiles.controls).forEach(p => {
        const file = companyInfoFiles.get(p).value;
        !!file
          ? documentsFormGroup.get(p + 'Id').setValue(uuid() + extractFileExtension(file))
          : documentsFormGroup.get(p + 'Id').reset();
      });
    });
  }
}
