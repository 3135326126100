import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AgmInfoWindow, AgmMap } from '@agm/core';
import { SectionComponent } from '../section.component';
import { AppConfigService } from '../../../../app-config.service';
import { MapsService } from '../../../../shared/service/client/maps.service';
import { UserSessionService } from '../../../../shared/service/authentication/user-session.service';
import { SectionType } from '../section.type';
import { FormGroup } from '@angular/forms';
import { CountlyService } from '../../../../shared/service/countly.service';

@Component({
  selector: 'app-debit-card',
  templateUrl: './debit-card.component.html',
  styleUrls: ['./debit-card.component.scss'],
})
export class DebitCardComponent extends SectionComponent implements OnInit {
  @ViewChildren('infoWindow')
  components: QueryList<AgmInfoWindow>;

  @ViewChild('gm')
  gm: AgmMap;

  id = -1;
  public lat = 51.678418;
  public lng = 7.809007;
  public city = 'Test';
  public address = 'Test';

  public points: Array<any> = [];

  constructor(
    private environment: AppConfigService,
    private mapsService: MapsService,
    private userSessionService: UserSessionService,
    private countlyService: CountlyService
  ) {
    super();
  }

  ngOnInit() {
    this.countlyService.trackDebitCard();
    this.userSessionService.isLoggedInObservable().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.updateBranchesList();
      }
    });

    this.disableControls();

    const seatFormGroup = this.formGroup.get(SectionType.CompanyData).get('seat') as FormGroup;

    seatFormGroup.valueChanges.subscribe(() => {
      const value = seatFormGroup.getRawValue();
      if (!value.addressField) {
        return;
      }
      this.resolveAddress(value.addressField);
    });
  }

  distance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // km
    const dLat1: number = this.toRad(lat2 - lat1);
    const dLon1: number = this.toRad(lon2 - lon1);
    const dLat2: number = this.toRad(lat1);
    const dLon2: number = this.toRad(lat2);

    const a =
      Math.sin(dLat1 / 2) * Math.sin(dLat1 / 2) +
      Math.sin(dLon1 / 2) * Math.sin(dLon1 / 2) * Math.cos(dLat2) * Math.cos(dLon2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  public toRad(value: number): number {
    return (value * Math.PI) / 180;
  }

  public onMarkerMouseClick(infoWindow: any, id: any) {
    this.components.forEach(c => {
      if (c.isOpen) {
        c.close();
      }
    });

    this.points.forEach(point => {
      if (point.id === id) {
        this.sectionFormGroup.get('city').setValue(point.city);
        this.sectionFormGroup.get('address').setValue(point.address);
      }
    });
    this.id = id;
    infoWindow.open();
  }

  private updateBranchesList(): void {
    this.mapsService.getBranchesList().subscribe(data => {
      data.forEach(point => {
        this.points.push({
          lng: parseFloat(point.lng),
          lat: parseFloat(point.lat),
          icon: '/assets/images/branch_marker.png',
          address: point.address,
          id: point.id,
          city: point.city
        });
      });
    });
  }

  private resolveAddress(address: string) {
    this.mapsService.resolveAddress(address).subscribe(location => {
      const result = location['results'][0];
      this.lng = result.geometry.location.lng;
      this.lat = result.geometry.location.lat;

      this.id = this.points.reduce((prev, curr) => {
        const cpos = this.distance(this.lat, this.lng, parseFloat(curr.lat), parseFloat(curr.lng));
        const ppos = this.distance(this.lat, this.lng, parseFloat(prev.lat), parseFloat(prev.lng));
        return cpos < ppos ? curr : prev;
      }).id;

      this.points.forEach(point => {
        if (point.id === this.id) {
          this.sectionFormGroup.get('city').setValue(point.city);
          this.sectionFormGroup.get('address').setValue(point.address);
        }
      });
    });
  }

  private disableControls(): void {
    this.sectionFormGroup.get('city').disable();
    this.sectionFormGroup.get('address').disable();
    this.sectionFormGroup.get('maxPurchases').disable();
    this.sectionFormGroup.get('maxAdvances').disable();
  }

  public get cardDetailsControls(): string[] {
    return [
      'nameOnTheCard',
      'companyName',
      'purchaseLimit',
      'maxPurchases',
      'advanceLimit',
      'maxAdvances',
    ];
  }
}
