import { Injectable } from '@angular/core';
// @ts-ignore
import * as Countly from 'countly-sdk-web';
import {AppConfigService} from '../../app-config.service';
import {EventNames} from './analytics.constants';

@Injectable({
  providedIn: 'root'
})
export class CountlyService {

  constructor(private appConfigService: AppConfigService) {
  }

  public initCountly(): void {
    if (window.location.hostname) {
      Countly.q = Countly.q || [];
      Countly.init({
        debug: false,
        app_key: this.appConfigService.config!.CountlyAppKey,
        url: this.appConfigService.config!.CountlyUrl,
        app_version: 1,
        use_session_cookie: false
      });
      Countly.q.push(['track_sessions']);
      Countly.q.push(['track_scrolls']);
      Countly.q.push(['track_errors']);

    }
  }

  public trackEvent(eventName: string): void {
    Countly.q.push(['add_event', { key: eventName, count: 1 }]);
  }

  public trackEventWithSegment(eventName: string, segment: any): void {
    Countly.q.push(['add_event', { key: eventName, count: 1, segmentation: segment }]);
  }

  public trackCompanyInfo(): void {
    console.log("Company Info countly event");
    this.trackEvent(EventNames.COMPANY_INFO);
  }

  public trackAccountSelector(): void {
    console.log("Account selector countly event");
    this.trackEvent(EventNames.ACCOUNT_SELECTOR);
  }

  public trackCompanyData(): void {
    console.log("Company Data countly event");
    this.trackEvent(EventNames.COMPANY_DATA);
  }

  public trackTrusteeData(): void {
    console.log("Trustee Data countly event");
    this.trackEvent(EventNames.TRUSTEE_DATA);
  }

  public trackDebitCard(): void {
    console.log("Debit Card countly event");
    this.trackEvent(EventNames.DEBIT_CARD);
  }

  public trackStatements(): void {
    console.log("Statements countly event");
    this.trackEvent(EventNames.STATEMENTS);
  }

  public trackVerification(): void {
    console.log("Verification countly event");
    this.trackEvent(EventNames.VERIFICATION);
  }

  public trackThankYouPage(): void {
    console.log("Thank You Page countly event");
    this.trackEvent(EventNames.THANK_YOU);
  }



}