import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-popup-info-dialog',
  templateUrl: './popup-info-dialog.component.html',
  styleUrls: ['./popup-info-dialog.component.scss'],
})
export class PopupInfoDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PopupInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    console.log(data);
  }

  public onResume(): void {
    this.dialogRef.close();
  }
}
