import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpenAccountComponent } from './components/pages/open-account/open-account.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/open-account',
    pathMatch: 'full',
  },
  {
    path: 'open-account',
    component: OpenAccountComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
