import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class ValidationMessagesHelper {
  constructor(private translate: TranslateService) {}

  public createErrorMessages(control: FormControl): string {
    return Object.keys(control.errors)
      .map(error => {
        return this.translate.instant('validationMessages.' + error);
      })
      .reduce((previousValue, currentValue) => {
        return previousValue + '. ' + currentValue;
      }, '')
      .substring(1);
  }
}
