import { Component , OnInit } from '@angular/core';
import { SectionComponent } from '../section.component';
import { MatCheckboxChange } from '@angular/material';
import { CountlyService } from '../../../../shared/service/countly.service';

export const packagesOptions = {
  option1: {
    packages: ['package-accountant'],
    tabIndex: 0,
  },
  option2: {
    packages: ['package-1', 'package-1-special', 'package-2'],
    tabIndex: 1,
  },
  option3: {
    packages: ['package-2', 'package-1', 'package-3'],
    tabIndex: 2,
  },
  option4: {
    packages: ['package-1', 'package-2', 'package-3'],
    tabIndex: 3,
  },
  option5: {
    packages: ['package-2', 'package-3', 'package-4'],
    tabIndex: 4,
  },
  option6: {
    packages: ['package-2', 'package-4', 'package-3'],
    tabIndex: 5,
  },
};

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
})
export class AccountSelectorComponent extends SectionComponent implements OnInit
 {
  public isNewCompany = false;
  private selectedIndex = packagesOptions.option3.tabIndex;
  constructor(private countlyService: CountlyService) {
    super();
  }
  public selectedLimit(option: any): void {
    this.selectedIndex = option;
  }

  public get isBookKeeper(): boolean {
    return this.formGroup.get('companyInfo.isBookKeeper').value;
  }

  public get selectedPackage(): string {
    return this.sectionFormGroup.get('selectedPackage').value;
  }
  public ngOnInit(): void {
    this.countlyService.trackAccountSelector();
  }
  public get selectedTabIndex(): number {
    return this.isBookKeeper
      ? packagesOptions.option1.tabIndex
      : this.isNewCompany
        ? packagesOptions.option2.tabIndex
        : this.selectedIndex;
  }

  public markAsNewCompany(checkBox: MatCheckboxChange): void {
    this.isNewCompany = checkBox.checked;
  }
}
