import { Component, Input } from '@angular/core';
import { CompanyFile } from '../company-type-files-matching';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
})
export class FilesComponent {
  @Input()
  public companyFiles: CompanyFile[];

  @Input()
  public formGroup: FormGroup;

  public extractFormControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }

  public getPlaceholder(fileName: string): string {
    return 'companyInfo.documents.' + fileName;
  }
}
