import { Component } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private environment: AppConfigService) {}

  public get callCenterPhoneNumber(): string {
    return this.environment.config.callCenterPhoneNumber;
  }
}
