import { Component, Input } from '@angular/core';
import { documentsCompanyTypeMatching } from './documents-company-type-matching';

@Component({
  selector: 'app-required-documents',
  templateUrl: './required-documents.component.html',
})
export class RequiredDocumentsComponent {
  @Input()
  public companyType: string;

  public get requiredDocumentsList(): string[] {
    return documentsCompanyTypeMatching[this.companyType];
  }
}
