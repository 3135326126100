// tslint:disable:max-line-length
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OpenAccountComponent } from './components/pages/open-account/open-account.component';
import { CompanyInfoComponent } from './components/dynamic-section-creator/sections/company-info/company-info.component';
import { AccountSelectorComponent } from './components/dynamic-section-creator/sections/account-selector/account-selector.component';
import { TrusteeDataComponent } from './components/dynamic-section-creator/sections/trustee-data/trustee-data.component';
import { CompanyDataComponent } from './components/dynamic-section-creator/sections/company-data/company-data.component';
import { DebitCardComponent } from './components/dynamic-section-creator/sections/debit-card/debit-card.component';
import {
  MAT_DATE_LOCALE,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatOptionModule, MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatStepperModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { DynamicSectionCreatorComponent } from './components/dynamic-section-creator/dynamic-section-creator.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StatementsContractsComponent } from './components/dynamic-section-creator/sections/statements-contracts/statements-contracts.component';
import { AppConfigService } from './app-config.service';
import { ConfigurationPipe } from './shared/pipe/configuration-pipe';
import { SmeonBffService } from './shared/service/client/smeon-bff.service';
import { MapsService } from './shared/service/client/maps.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MerlinIdnvService } from './shared/service/client/merlin-idnv.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CaptchaTokensService } from './shared/service/authentication/captcha-tokens.service';

import { BrexDatasetHelper } from './shared/brex-data-helper/brex-dataset.helper';
import { MapsConfigService } from './shared/service/maps-config.service';
import { CompanyRegistryService } from './shared/service/client/company-registry.service';
import { UserSessionService } from './shared/service/authentication/user-session.service';
import { FormGroupSideEffectCreator } from './shared/form-group/side-effects/form-group-side-effect.creator';
import { TrusteeDataSideEffects } from './shared/form-group/side-effects/trustee-data-side-effects.service';
import { UserDataComponent } from './components/user-data/user-data.component';
import { AddressDataComponent } from './components/address-data/address-data.component';
import { ContactDataComponent } from './components/contact-data/contact-data.component';
import { BeneficiaryDataSideEffectsService } from './shared/form-group/side-effects/beneficiary-data-side-effects.service';
import { IdVComponent } from './components/dynamic-section-creator/sections/id-v/id-v.component';
import { BeneficiaryOwnerComponent } from './components/dynamic-section-creator/sections/statements-contracts/beneficiary-owner/beneficiary-owner.component';
import { CardDataSideEffects } from './shared/form-group/side-effects/card-data-side-effects.service';
import { ValidationMessagesHelper } from './shared/validation-messages/validation-messages.helper';
import { FilesComponent } from './components/dynamic-section-creator/sections/company-info/files/files.component';
import { RequiredDocumentsComponent } from './components/dynamic-section-creator/sections/company-info/required-documents/required-documents.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { StatementsDataSideEffectsService } from './shared/form-group/side-effects/statements-data-side-effects.service';
import { CountryListComponent } from './components/country-list/country-list.component';
import { NationalityListComponent } from './components/nationality-list/nationality-list.component';
import { MerlinDocumentService } from './shared/service/client/merlin-document.service';
import { CompanyDataSideEffectsService } from './shared/form-group/side-effects/company-data-side-effects.service';
import { NgxMaskModule } from 'ngx-mask';
import { PopupErrorComponent } from './components/pages/open-account/popup-error-dialog/popup-error-dialog.component';
import { PackageSelectorComponent } from './components/dynamic-section-creator/sections/account-selector/package-selector/package-selector.component';
import { PopupWorkingTimeDialogComponent } from './components/pages/open-account/popup-working-time-dialog/popup-working-time-dialog.component';
import { IncomeRangeSelectorComponent } from './components/dynamic-section-creator/sections/account-selector/incoming-slider/income-range-selector.component';
import { CompanyInfoSideEffectsService } from './shared/form-group/side-effects/company-info-side-effects.service';
import { AddPhoneComponent } from './components/add-phone/add-phone.component';
import { DocumentsIdsHelper } from './shared/files-id-helper/documents-ids-helper.service';
import { PopupBrowserDialogComponent } from './components/pages/open-account/popup-browser-dialog/popup-browser-dialog.component';
import { PopupResumeSessionComponent } from './components/pages/open-account/popup-update-session/popup-resume-session.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BeforeUnloadService } from './shared/service/before-unload.service';
import { PopupInfoDialogComponent } from './components/info/popup-info-dialog/popup-info-dialog.component';
import { InfoComponent } from './components/info/info.component';
import { WorkingTimeComponent } from './components/working-time/working-time.component';
import { FileResultDialogComponent } from './components/pages/open-account/file-result-dialog/file-result-dialog.component';
import { PlannedTransactionErrorModalComponent } from './components/dynamic-section-creator/sections/statements-contracts/planned-transaction-error-modal/planned-transaction-error-modal.component';

export const materialModules = [
  BrowserAnimationsModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatButtonModule,
  MatStepperModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatIconModule,
  MatCheckboxModule,
  MatDividerModule,
  MatRadioModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatTabsModule,
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

declare var Countly: any;

export function init_app() {
  const isClientChrome =
    !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
  // Firefox shows empty page because of new Promise
  console.log('INIT');
  return () => {
    if (!isClientChrome) {
      return;
    } else {
      return new Promise(resolve => {
        const cly = document.createElement('script');
        cly.type = 'text/javascript';
        cly.async = true;
        // Enter url of script here (see below for other option)
        cly.src = 'https://cdn.jsdelivr.net/npm/countly-sdk-web@latest/lib/countly.min.js';
        cly.onload = function() {
          console.log('CINIT');
          Countly.init();
          resolve();
        };
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(cly, s);
      });
    }
  };
}

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    OpenAccountComponent,
    CompanyInfoComponent,
    AccountSelectorComponent,
    TrusteeDataComponent,
    CompanyDataComponent,
    DebitCardComponent,
    DynamicSectionCreatorComponent,
    StatementsContractsComponent,
    IdVComponent,
    ConfigurationPipe,
    FileUploadComponent,
    HeaderComponent,
    FooterComponent,
    PackageSelectorComponent,
    UserDataComponent,
    AddressDataComponent,
    ContactDataComponent,
    BeneficiaryOwnerComponent,
    FilesComponent,
    RequiredDocumentsComponent,
    TooltipComponent,
    CountryListComponent,
    NationalityListComponent,
    PopupErrorComponent,
    PopupWorkingTimeDialogComponent,
    IncomeRangeSelectorComponent,
    AddPhoneComponent,
    PopupBrowserDialogComponent,
    PopupResumeSessionComponent,
    SpinnerComponent,
    PopupInfoDialogComponent,
    InfoComponent,
    WorkingTimeComponent,
    FileResultDialogComponent,
    PlannedTransactionErrorModalComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot(),
    ...materialModules,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxCaptchaModule,
    RecaptchaModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    MatProgressSpinnerModule,
    FormsModule,
  ],
  entryComponents: [
    CompanyInfoComponent,
    AccountSelectorComponent,
    TrusteeDataComponent,
    CompanyDataComponent,
    DebitCardComponent,
    StatementsContractsComponent,
    IdVComponent,
    PackageSelectorComponent,
    PopupErrorComponent,
    PopupWorkingTimeDialogComponent,
    PopupResumeSessionComponent,
    PopupBrowserDialogComponent,
    PopupInfoDialogComponent,
    FileResultDialogComponent,
    PlannedTransactionErrorModalComponent
  ],
  providers: [
    SmeonBffService,
    MapsService,
    AppConfigService,
    MerlinIdnvService,
    MerlinDocumentService,
    BrexDatasetHelper,
    DocumentsIdsHelper,
    CompanyRegistryService,
    UserSessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    { provide: APP_INITIALIZER, useFactory: init_app, multi: true },
    { provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfigService },
    CaptchaTokensService,
    FormGroupSideEffectCreator,
    TrusteeDataSideEffects,
    BeneficiaryDataSideEffectsService,
    CardDataSideEffects,
    StatementsDataSideEffectsService,
    CompanyDataSideEffectsService,
    CompanyInfoSideEffectsService,
    ValidationMessagesHelper,
    BeforeUnloadService,
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
