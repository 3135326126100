import {Component, OnDestroy, OnInit} from '@angular/core';
import {SectionComponent} from '../section.component';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanyFile, companyTypeFilesMatching} from './company-type-files-matching';
import {SectionType} from '../section.type';
import {ContactsFormGroupBuilder} from '../../../../shared/form-group/contacts-form-group-builder.service';
import {Subscription} from 'rxjs';
import {BrexDatasetModel} from '../../../../model/brex-dataset.model';
import {SpinnerService} from '../../../../shared/service/spinner.service';
import {CompanyRegistryService} from '../../../../shared/service/client/company-registry.service';
import {BrexDatasetHelper} from '../../../../shared/brex-data-helper/brex-dataset.helper';
import {MatDialog} from '@angular/material';
import {PopupErrorComponent} from '../../../pages/open-account/popup-error-dialog/popup-error-dialog.component';
import {CountlyService} from '../../../../shared/service/countly.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent extends SectionComponent implements OnInit, OnDestroy {
  public companyFiles: CompanyFile[];
  private companyNameChangesSubscription: Subscription;
  private companyTaxIdChangesSubscription: Subscription;
  private companyTypeValueChangesSubscription: Subscription;
  private companyDataForPrivateEntrepreneur: Boolean = false;

  public types = [
    'privateEntrepreneur',
    'limitedLiability',
    'newCompany',
    'individualCompany',
    'cooperative',
    'generalPartnership',
  ];

  public get contactCartValidationStatus(): string {
    const contactsFormGroupValidationStatus = this.getFormControlsValidationStatus(
      ['email', 'phoneNumber'],
      this.contactsFormGroup
    );
    const privacyStatementAcceptedValidationStatus = this.getSectionControlsValidationStatus([
      'isPrivacyStatementAccepted',
    ]);

    const isInvalid =
      contactsFormGroupValidationStatus === 'invalid' ||
      privacyStatementAcceptedValidationStatus === 'invalid';
    const isValid = privacyStatementAcceptedValidationStatus === 'valid';

    return isInvalid ? 'invalid' : isValid ? 'valid' : '';
  }

  private defaultFormRow: any;
  public contactsFormGroup: FormGroup;

  public get companyType(): string {
    return this.sectionFormGroup.get('companyType').value;
  }

  public get filesFormGroup(): FormGroup {
    return this.sectionFormGroup.get('files') as FormGroup;
  }

  public get companyNameFieldName(): string {
    return this.isPrivateEntrepreneur
      ? 'companyData.companyDetails.entrepreneurName'
      : 'companyData.companyDetails.companyName';
  }

  public get companyFetched(): string {
    return this.formGroup.get('companyInfo.companyFetched').value;
  }

  public get isCompanyFound(): boolean {
    return this.companyName !== '';
  }

  public get companyName(): string {
    return this.formGroup.get('companyData.companyDetails.companyName').value;
  }

  constructor(private contactsFormGroupBuilder: ContactsFormGroupBuilder,
              private companyRegistryService: CompanyRegistryService,
              private brexDataSetHelper: BrexDatasetHelper,
              private spinnerService: SpinnerService,
              private dialog: MatDialog,
              private countlyService: CountlyService) {
    super();
  }

  public ngOnInit(): void {
    this.countlyService.trackCompanyInfo();
    this.initSectionForm();
    this.setDefaultFormRow();
    this.initContactsForm();
    this.companyTypeValueChangesSubscription = this.createCompanyTypeValueChangesSubscription();
  }

  public onTypeChanges(): void {
    this.formGroup.patchValue(this.defaultFormRow);
    this.formGroup.get('companyData.seat').enable({onlySelf: false});
    this.sectionFormGroup.get('companyTaxId').enable();
    this.sectionFormGroup.get('userTaxId').enable();

    const statementsFormGroup = <FormGroup>this.formGroup.get(SectionType.StatementsAndContracts);
    const sameAsUserFormControl = <FormControl>statementsFormGroup.get('sameAsUser');
    const ownershipRateFormControl = statementsFormGroup.get(
      'beneficiaryOwner.ownership.ownershipRate'
    );
    const typeOfOwnershipFormControl = <FormControl>(
      statementsFormGroup.get('beneficiaryOwner.ownership.typeOfOwnership')
    );
    const companyNameOnCard = this.formGroup.get('debitCard.companyName');

    const onPrivateEntrepreneur = () => {
      this.resetUserTaxId();
      sameAsUserFormControl.patchValue(true);
      ownershipRateFormControl.disable();
      typeOfOwnershipFormControl.disable();
      ownershipRateFormControl.patchValue('100');
      typeOfOwnershipFormControl.patchValue('ratio');
      companyNameOnCard.setValue('Egyéni Vállalkozó');
      companyNameOnCard.disable();
      this.sectionFormGroup.get('companyTaxId').clearValidators();
      this.sectionFormGroup.get('companyTaxId').updateValueAndValidity();
    };

    const onEnterprise = () => {
      this.sectionFormGroup.get('userTaxId').enable();
      sameAsUserFormControl.patchValue(false);
      ownershipRateFormControl.enable();
      typeOfOwnershipFormControl.enable();
      ownershipRateFormControl.patchValue('');
      typeOfOwnershipFormControl.patchValue('');
    };

    this.isPrivateEntrepreneur ? onPrivateEntrepreneur() : onEnterprise();

    // When the 'type' is changed, the countryOfBirthOther becomes enabled and it has to be manually disabled
    // because it is required and not visible by default
    this.formGroup.get('statements.beneficiaryOwner.general.countryOfBirthOther').disable();
  }

  private initSectionForm(): void {
    this.sectionFormGroup.get('companyTaxId').disable();
    this.sectionFormGroup.get('userTaxId').disable();
    this.filesFormGroup.disable({onlySelf: false});
  }

  private manageFileUploadFormGroup(): void {
    this.filesFormGroup.disable({onlySelf: false});

    this.companyFiles = companyTypeFilesMatching[this.companyType];

    Object.values(this.companyFiles).forEach(value => {
      this.filesFormGroup.get(value.fileName).enable();
    });
  }

  private resetUserTaxId() {
    this.sectionFormGroup.get('userTaxId').reset();
    this.sectionFormGroup.get('userTaxId').disable();
  }

  private setDefaultFormRow(): void {
    this.defaultFormRow = this.formGroup.getRawValue();
    delete this.defaultFormRow.companyInfo.companyType;
  }

  public get companyInformationControls(): string[] {
    return ['companyType', 'companyTaxId', 'userTaxId'];
  }

  private initContactsForm(): void {
    this.contactsFormGroup = this.contactsFormGroupBuilder.getContactsFormGroup();

    this.companyNameChangesSubscription = this.formGroup
      .get('companyData.companyDetails.companyName')
      .valueChanges.subscribe(() => {
        this.contactsFormGroup.get('companyName').patchValue(this.companyName);
      });

    const companyTaxIdControl = this.sectionFormGroup.get('companyTaxId');

    this.companyTaxIdChangesSubscription = companyTaxIdControl.valueChanges.subscribe(() => {
      this.contactsFormGroup.get('companyTaxId').patchValue(companyTaxIdControl.value);
    });
  }

  public getContactFormErrorMessages(controlName: string): string {
    return this.validationMessagesHelper.createErrorMessages(<FormControl>(
      this.contactsFormGroup.get(controlName)
    ));
  }

  public isContactControlInvalid(controlName: string): boolean {
    return this.contactsFormGroup.get(controlName).invalid;
  }

  public ngOnDestroy(): void {
    if (!!this.companyNameChangesSubscription) {
      this.companyNameChangesSubscription.unsubscribe();
    }
    if (!!this.companyTypeValueChangesSubscription) {
      this.companyTypeValueChangesSubscription.unsubscribe();
    }
  }

  private createCompanyTypeValueChangesSubscription(): Subscription {
    return this.sectionFormGroup.get('companyType').valueChanges.subscribe(() => {
      this.manageFileUploadFormGroup();
    });
  }

  getPrivateEntrepreneurCompanyData(userTaxId: any) {
    this.spinnerService.showSpinner();
    this.companyRegistryService
      .getCompanyRegistry(userTaxId.value)
      .subscribe(
        data => {
          const trimName = data.name;
          data.name = trimName.replace(/\s+$/, '');
          this.spinnerService.hideSpinner();
          this.formGroup.get('companyInfo.companyFetched').setValue('true');
          this.brexDataSetHelper.patchForm(this.formGroup, data as BrexDatasetModel);
          this.sectionFormGroup.get('companyTaxId').disable();
        },
        error => {
          this.spinnerService.hideSpinner();
          this.formGroup.get('companyInfo.companyFetched').setValue('error');
          const errors = ['companyNotFound'];
          this.dialog.open(PopupErrorComponent, {
            width: '500px',
            data: { errors: errors },
          });
        }
      );
  }
}
