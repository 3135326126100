import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { SectionType } from '../../../components/dynamic-section-creator/sections/section.type';

@Injectable()
export class BeneficiaryDataSideEffectsService implements OnDestroy {
  private sameAsUserValueChangesSubscriptions: Subscription;

  public ngOnDestroy(): void {
    if (this.sameAsUserValueChangesSubscriptions) {
      this.sameAsUserValueChangesSubscriptions.unsubscribe();
    }
  }

  public addSideEffect(form: FormGroup): void {
    this.sameAsUserValueChangesSubscriptions = this.createSameAsUserValueChangesSubscriptions(form);
  }

  private createSameAsUserValueChangesSubscriptions(form: FormGroup) {
    const statementsAndContractsDataForm = form.get(SectionType.StatementsAndContracts);
    const beneficiaryOwnerDataForm = <FormGroup>(
      statementsAndContractsDataForm.get('beneficiaryOwner')
    );
    let trusteeDataValueChangesSubscriptions: Subscription;

    const beneficiaryOwnerGeneralDefaultDataRow = (<FormGroup>(
      beneficiaryOwnerDataForm.get('general')
    )).getRawValue();
    const beneficiaryOwnerAddressDefaultDataRow = (<FormGroup>(
      beneficiaryOwnerDataForm.get('address')
    )).getRawValue();
    const beneficiaryOwnerContactDefaultDateRow = (<FormGroup>(
      beneficiaryOwnerDataForm.get('contact')
    )).getRawValue();

    return statementsAndContractsDataForm.get('sameAsUser').valueChanges.subscribe(value => {
      const beneficiaryOwnerGeneralForm = beneficiaryOwnerDataForm.get('general');
      const beneficiaryOwnerAddressForm = beneficiaryOwnerDataForm.get('address');
      const beneficiaryOwnerContactForm = beneficiaryOwnerDataForm.get('contact');

      const disableBeneficiaryOwnerForm = () => {
        beneficiaryOwnerGeneralForm.disable();
        beneficiaryOwnerAddressForm.disable();
        beneficiaryOwnerContactForm.disable();
      };

      const createTrusteeDataValueChangesSubscriptions = () => {
        return form.get('trusteeData').valueChanges.subscribe(() => {
          this.copyForm(<FormGroup>form.get('trusteeData'), beneficiaryOwnerDataForm);
          disableBeneficiaryOwnerForm();
        });
      };

      const subscribeToCopyUserData = () => {
        trusteeDataValueChangesSubscriptions = createTrusteeDataValueChangesSubscriptions();

        this.copyForm(<FormGroup>form.get('trusteeData'), beneficiaryOwnerDataForm);
        disableBeneficiaryOwnerForm();
      };

      const clearUserData = () => {
        if (trusteeDataValueChangesSubscriptions) {
          trusteeDataValueChangesSubscriptions.unsubscribe();
        }

        beneficiaryOwnerGeneralForm.patchValue(beneficiaryOwnerGeneralDefaultDataRow);
        beneficiaryOwnerGeneralForm.enable();

        beneficiaryOwnerGeneralForm.get('countryOfBirthOther').disable();

        beneficiaryOwnerAddressForm.patchValue(beneficiaryOwnerAddressDefaultDataRow);
        beneficiaryOwnerAddressForm.enable();

        beneficiaryOwnerContactForm.patchValue(beneficiaryOwnerContactDefaultDateRow);
        beneficiaryOwnerContactForm.enable();
      };

      value ? subscribeToCopyUserData() : clearUserData();
    });
  }

  private copyForm(copyFromForm: FormGroup, copyToForm: FormGroup): void {
    copyToForm.patchValue(copyFromForm.getRawValue());
  }
}
