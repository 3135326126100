export enum FileType {
  ES3 = '.es3',
  ET3 = '.et3',
  ODT = '.odt',
  PDF = '.pdf',
  JPEG = '.jpeg',
  JPG = '.jpg',
  TIF = '.tif',
  TIFF = '.tiff',
  PNG = '.png',
  BMP = '.bmp',
}
