import { Injectable } from '@angular/core';
import { MerlinDocumentService } from './client/merlin-document.service';
import { TransactionIdService } from './transaction-id.service';
import { FormGroup } from '@angular/forms';
import { SectionType } from '../../components/dynamic-section-creator/sections/section.type';
import { StoreDocumentsCommandV1Model } from '../../model/command-models/store-documents-command-v1.model';
import { DocumentsIdsHelper } from '../files-id-helper/documents-ids-helper.service';
import { SmeonBffService } from './client/smeon-bff.service';
import { Observable } from 'rxjs';
import { CommandRequestModel } from '../../model/command-models/command-request.model';

@Injectable({
  providedIn: 'root',
})
export class StoreDocumentsService {
  constructor(
    private documentService: MerlinDocumentService,
    private bffService: SmeonBffService,
    private transactionIdService: TransactionIdService,
    private documentsIdsHelper: DocumentsIdsHelper
  ) {}

  public uploadAndStoreDocuments(form: FormGroup, callback: void): void {
    const companyInfoFiles = <FormGroup>form.get(SectionType.CompanyInfo).get('files');
    const fileFields = Object.keys(companyInfoFiles.controls);

    const filesForUpload = fileFields.filter(p => !!companyInfoFiles.get(p).value);
    filesForUpload.map(vlera => console.log('Vlera tjeter ' + JSON.stringify(companyInfoFiles.get(vlera).value)));

    Promise.all(
      filesForUpload.map(p =>
        this.documentService
          .upload(
            companyInfoFiles.get(p).value,
            form
              .get(SectionType.CompanyInfo)
              .get('documents')
              .get(p + 'Id').value
          )
          .toPromise()
      )
    ).then(() => {
      this.storeDocuments(form)
        .toPromise()
        .then(() => callback);
    });
  }

  private storeDocuments(form: FormGroup): Observable<boolean> {
    const storeDocumentsCommandV1Model = new StoreDocumentsCommandV1Model();
    storeDocumentsCommandV1Model.transactionId = this.transactionIdService.transactionId;

    storeDocumentsCommandV1Model.documentIds = this.documentsIdsHelper.getEAktaFilesIds(form);
    storeDocumentsCommandV1Model.documentIds.push(this.documentsIdsHelper.getPdfFileId());
    storeDocumentsCommandV1Model.documentIds.push(this.documentsIdsHelper.getMetadataFileId());

    const request = new CommandRequestModel();
    request.commands.push(storeDocumentsCommandV1Model);

    return this.bffService.command(request);
  }
}
