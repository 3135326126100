import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkingTimeService } from '../../shared/service/working-time.service';
import { Subscription } from 'rxjs';
import { PopupWorkingTimeDialogComponent } from '../pages/open-account/popup-working-time-dialog/popup-working-time-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BankingDayService } from '../../shared/service/banking-day.service';

@Component({
  selector: 'app-working-time',
  templateUrl: './working-time.component.html',
  styleUrls: ['./working-time.component.scss'],
})
export class WorkingTimeComponent implements OnInit, OnDestroy {
  private workingTimeSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private workingTimeService: WorkingTimeService,
    private bankingDayService: BankingDayService
  ) {}

  public ngOnInit(): void {
    this.checkingWorkingTime();
  }

  public ngOnDestroy(): void {
    if (!!this.workingTimeSubscription) {
      this.workingTimeSubscription.unsubscribe();
    }
  }

  private checkingWorkingTime(): void {
    let isRemindingWasShown = false;
    let isClosingTimeWasShown = false;

    this.workingTimeSubscription = this.workingTimeService.isWorkingTimeObservable.subscribe(
      next => {
        if (next.isRemindingTime && !isRemindingWasShown) {
          isRemindingWasShown = true;
          this.showWorkingTimePopup();
        }

        if (next.isClosingTime && !isClosingTimeWasShown) {
          isClosingTimeWasShown = true;
          this.showWorkingTimePopup();
        }

        if (!this.bankingDayService.isBankingDay && !isClosingTimeWasShown) {
          isClosingTimeWasShown = true;
          this.showWorkingTimePopup();
        }
      }
    );
  }

  private showWorkingTimePopup(): void {
    this.dialog.open(PopupWorkingTimeDialogComponent, {
      width: '500px',
      disableClose: true,
    });
  }
}
