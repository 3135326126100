import { Injectable } from '@angular/core';
import { AppConfigService } from '../../app-config.service';

@Injectable()
export class BeforeUnloadService {
  constructor(private configService: AppConfigService) {}

  private onChangeListener = e => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  public addEventListener(): void {
    if (this.isUsingBeforeUnload()) {
      window.addEventListener('beforeunload', this.onChangeListener);
    }
  }

  public removeEventListener(): void {
    if (this.isUsingBeforeUnload()) {
      window.removeEventListener('beforeunload', this.onChangeListener);
    }
  }

  private isUsingBeforeUnload(): boolean {
    return this.configService.config.useBeforeUnload === 'true';
  }
}
