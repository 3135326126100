import {Injectable} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {FormGroupSideEffectCreator} from './side-effects/form-group-side-effect.creator';

@Injectable({
  providedIn: 'root',
})
export class AcceptanceFormGroupBuilder {
  constructor(
    private formBuilder: FormBuilder,
    private formSideEffectService: FormGroupSideEffectCreator
  ) {}

  private _acceptanceForm: FormGroup;

  private createAcceptanceFormGroup(): FormGroup {
    this._acceptanceForm = this.formBuilder.group({
      isLastPdfDownloaded: this.formBuilder.control(false, Validators.requiredTrue),
      isAgreedDataStoring: this.formBuilder.control(false, Validators.requiredTrue),
      isCrsAndFatcaWereRead: this.formBuilder.control(false, Validators.requiredTrue),
      isAccepted: this.formBuilder.control(false, Validators.requiredTrue),
      question1: this.formBuilder.control('', Validators.required),
      question2: this.formBuilder.control('', Validators.required)
    });

    this.formSideEffectService.addAcceptanceFormSideEffect(this._acceptanceForm);

    return this._acceptanceForm;
  }

  public getAcceptanceFormGroup(): FormGroup {
    return !!this._acceptanceForm ? this._acceptanceForm : this.createAcceptanceFormGroup();
  }
}
