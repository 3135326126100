import { VerificationAddress } from './verification-address.model';

export class VerificationRequest {
  public transactionId: string;

  public agentId: string;

  public dateOfBirth: string;

  public placeOfBirth: string;

  public birthName: string;

  public trackingId: string;

  public emailAddress: string;

  public firstName: string;

  public gender: string;

  public lastName: string;

  public mobilePhoneNumber: string;

  public nationality: string;

  public language: string;

  public custom1: string;

  public address: VerificationAddress;
}
