import { Injectable } from '@angular/core';
import { SmeonBffService } from './client/smeon-bff.service';
import { FormGroup } from '@angular/forms';
import { SendContactDataCommandV1Model } from '../../model/command-models/send-contact-data-command-v1.model';
import { CommandRequestModel } from '../../model/command-models/command-request.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactsPostService {
  constructor(private smeonBffService: SmeonBffService) {}

  public postContacts(contactsForm: FormGroup): Observable<boolean> {
    const request = new CommandRequestModel();
    request.commands.push(this.prepareSendContactDataCommandV1Model(contactsForm));

    return this.smeonBffService.command(request);
  }

  private prepareSendContactDataCommandV1Model(
    contactsForm: FormGroup
  ): SendContactDataCommandV1Model {
    const sendContactDataCommandV1Model = new SendContactDataCommandV1Model();
    sendContactDataCommandV1Model.companyName = contactsForm.get('companyName').value;
    sendContactDataCommandV1Model.companyTaxId = contactsForm.get('companyTaxId').value;
    sendContactDataCommandV1Model.email = !!contactsForm.get('email').value
      ? contactsForm.get('email').value
      : '-';
    sendContactDataCommandV1Model.phoneNumber = contactsForm.get('phoneNumber').value
      ? '+36 ' + contactsForm.get('phoneNumber').value
      : '-';

    return sendContactDataCommandV1Model;
  }
}
