import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationMessagesHelper } from '../../shared/validation-messages/validation-messages.helper';

@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss'],
})
export class AddressDataComponent {
  @Input()
  public formGroup: FormControl;

  constructor(private validationMessagesHelper: ValidationMessagesHelper) {}

  public getErrorMessages(controlKey: string): string {
    const control = this.formGroup.get(controlKey) as FormControl;

    return this.validationMessagesHelper.createErrorMessages(control);
  }

  public isInvalid(controlKey: string): boolean {
    return (this.formGroup.get(controlKey) as FormControl).invalid;
  }
}
