import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransactionIdService } from '../transaction-id.service';
import { v4 as uuid } from 'uuid';
import { AppConfigService } from '../../../app-config.service';
import { Observable } from 'rxjs';
import { UserSessionService } from '../authentication/user-session.service';
import {any} from 'codelyzer/util/function';
import {ScanResultDto} from '../../../components/pages/open-account/scanResultDto';
import {FileResults} from '../../../components/pages/open-account/fileResults';

@Injectable()
export class MerlinDocumentService {
  constructor(
    private http: HttpClient,
    private transactionIdService: TransactionIdService,
    private appConfigService: AppConfigService,
    private userSessionService: UserSessionService,
  ) {
  }

  public upload(file: File, documentId): Observable<any> {
    const targetUrl = this.appConfigService.config.baseUrl + '/merlin-smeon-document-svc/v1/upload';

    const data = new FormData();
    data.append('file', file, documentId);
    data.append('requestId', uuid());
    data.append('sessiontId', this.transactionIdService.transactionId);
    data.append('documentId', sessionStorage.getItem('documentId'));

    console.log('Data ' + JSON.stringify(data));

    return this.http.post(targetUrl, data, {
      headers: this.userSessionService.authorizationDocumentServiceHeader,
    });
  }

  public async scanFiles (file: File): Promise<Map<String, String[]>> {
    const targetUrl = this.appConfigService.config.baseUrl + '/merlin-smeon-document-svc/v1/scan';
    const data = new FormData();
    console.log('Fajllat ' + file);
    // @ts-ignore
    data.append('file', file);
    data.append('documentId', sessionStorage.getItem('uuid'));

    console.log('Data ' + JSON.stringify(data));

    const res = await this.http.post(targetUrl, data, {
      headers: this.userSessionService.authorizationDocumentServiceHeader,
    }).toPromise();
    const obj = res as ScanResultDto;
    console.log('Statusi : ' + obj.statusClean);
    console.log('UUIDJA ' + sessionStorage.getItem('uuid'));
    if (!obj.statusClean.valueOf()) {
      console.log('Hini ne if');
      // @ts-ignore
      FileResults.listOfInfectedFilesMap.set(sessionStorage.getItem('uuid'), file.name);
      console.log('MAPIIIII' + FileResults.listOfInfectedFilesMap.get(sessionStorage.getItem('uuid')));
      console.log('U kry');
      return FileResults.listOfInfectedFilesMap;

    }
    // => {
  }

  public getResult (): any {
    const documentId = sessionStorage.getItem('uuid');
    const targetUrl = this.appConfigService.config.baseUrl + '/merlin-smeon-document-svc/v1/scan-result/' + documentId;
    const req =  this.http.get(targetUrl, {
      headers: this.userSessionService.authorizationDocumentServiceHeader,
    });

    req.subscribe();
  }
}
