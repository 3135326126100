import { Injectable } from '@angular/core';
import { SmeonBffService } from './client/smeon-bff.service';
import { TransactionIdService } from './transaction-id.service';
import { interval, Observable } from 'rxjs';
import { IdentificationStatusQueryV1Model } from '../../model/query-models/identification-status-query-v1.model';
import { UserSessionService } from './authentication/user-session.service';
import { AppConfigService } from '../../app-config.service';

@Injectable({
  providedIn: 'root',
})
export class IdentificationStatusService {
  private validIdnvResults = [
    'SUCCESS',
    'SUCCESS_DATA_CHANGED',
    'FRAUD_SUSPICION_PENDING',
    'FRAUD_SUSPICION_CONFIRMED',
    'REVIEW_PENDING',
  ];

  constructor(
    private smeonBffService: SmeonBffService,
    private transactionIdService: TransactionIdService,
    private userSessionService: UserSessionService,
    private environment: AppConfigService
  ) {}

  public isVerificationDone(): Observable<boolean> {
    const query = new IdentificationStatusQueryV1Model();
    query.transactionId = this.transactionIdService.transactionId;
    query.application = this.environment.config['idnvApplicationName'];

    return new Observable(subscriber => {
      const schedule = interval(3000).subscribe(() => {
        if (this.userSessionService.isLoggedIn) {
          this.smeonBffService.query<VerificationStatus>(query).subscribe(verification => {
            const isVerificationDone = this.isDone(verification);
            subscriber.next(isVerificationDone);
            if (isVerificationDone) {
              schedule.unsubscribe();
              subscriber.complete();
            }
          });
        }
      });
    });
  }

  private isDone(verification: VerificationStatus): boolean {
    return this.validIdnvResults
      .map(status => verification.status === status)
      .reduce((s1, s2) => s1 || s2, false);
  }
}

export interface VerificationStatus {
  status: string;
}
