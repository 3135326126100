import { Component, OnInit } from '@angular/core';
import { SectionComponent } from '../section.component';
import { SectionType } from '../section.type';
import { FormControl } from '@angular/forms';
import { CountlyService } from '../../../../shared/service/countly.service';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss'],
})
export class CompanyDataComponent extends SectionComponent implements OnInit {
  public isSameAsSeat: boolean;
  public isFirstAdditionalMobileNumberDisplayed = false;
  public isSecondAdditionalMobileNumberDisplayed = false;
  constructor(private countlyService: CountlyService) {
    super();
  }
  public setFirstAdditionalMobileNumberDisplayed(isDisplayed: boolean) {
    if (!isDisplayed) {
      this.firstAdditionalPhoneControl.reset();
    }
    this.isFirstAdditionalMobileNumberDisplayed = isDisplayed;
  }
  public setSecondAdditionalMobileNumberDisplayed(isDisplayed: boolean) {
    if (!isDisplayed) {
      this.secondAdditionalPhoneNumber.reset();
    }
    this.isSecondAdditionalMobileNumberDisplayed = isDisplayed;
  }

  public get naceCodePlaceholder(): string {
    return this.isPrivateEntrepreneur
      ? 'companyData.companyDetails.naceCode.privateEntrepreneur'
      : 'companyData.companyDetails.naceCode.enterprise';
  }

  private get firstAdditionalPhoneControl(): FormControl {
    return <FormControl>this.sectionFormGroup.get('contactDetails.additionalMobileNumber1');
  }

  private get secondAdditionalPhoneNumber(): FormControl {
    return <FormControl>this.sectionFormGroup.get('contactDetails.additionalMobileNumber2');
  }

  public get companyNameFieldName(): string {
    return this.isPrivateEntrepreneur
      ? 'companyData.companyDetails.entrepreneurName'
      : 'companyData.companyDetails.companyName';
  }

  public get companyRegistrationNumberFieldName(): string {
    return this.isPrivateEntrepreneur
      ? 'companyData.companyDetails.registrationNumber'
      : 'companyData.companyDetails.companyRegistrationNumber';
  }

  public get selectedPackage(): string {
    return this.formGroup.get(SectionType.AccountSelector).get('selectedPackage').value;
  }

  public onChangeSameAsSeat(checkbox: any): void {
    this.isSameAsSeat = checkbox.checked;
    checkbox.checked
      ? this.copySeatFormValuesToAddressFormGroup()
      : this.resetAddressFormGroupValues();
  }

  public ngOnInit(): void {
    this.countlyService.trackCompanyData();
    this.sectionFormGroup.get('companyDetails').disable({ onlySelf: false });

    this.sectionFormGroup.get('fatcaDisclosure').disable();

    this.sectionFormGroup.get('contactDetails.correspondenceLanguage').disable();

    this.sectionFormGroup.get('accountDetails.accountStatementType').disable();
    this.sectionFormGroup.get('accountDetails.accountStatementFrequency').disable();
    this.sectionFormGroup.get('accountDetails.accountPackage').disable();
  }

  private copySeatFormValuesToAddressFormGroup(): void {
    this.extractFormGroup('address.addressField').setValue(
      this.extractFormGroup('seat.addressField').value
    );
    this.extractFormGroup('address.addressField').disable();
  }

  private resetAddressFormGroupValues(): void {
    this.extractFormGroup('address.addressField').reset();
    this.extractFormGroup('address.addressField').enable();
  }

  public isFatcaDisclosureEnabled(): boolean {
    return this.sectionFormGroup.get('fatcaDisclosure').enabled;
  }
}
