import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SectionType } from '../../../components/dynamic-section-creator/sections/section.type';

@Injectable()
export class CardDataSideEffects implements OnDestroy {
  private companyNameSubscription: Subscription;
  private fullNameSubscription: Subscription;
  private companyTypeSubscription: Subscription;
  private form: FormGroup;

  static updateName(nameOnTheCard: AbstractControl, fullName: string) {
    let name = fullName
      .replace('Betéti Társaság', 'Bt.')
      .replace('Korlátolt Felelősségű Társaság', 'Kft.')
      .replace('Közkereseti Társaság', 'Kkt.');

    if (name === ' ') {
      name = '';
    }

    const isNameValid = () => {
      return name.length <= 25;
    };

    const onNameValid = () => {
      nameOnTheCard.setValue(name);
      nameOnTheCard.disable();
    };

    const onNameInvalid = () => {
      nameOnTheCard.enable();
      nameOnTheCard.setValue(name);
    };

    isNameValid() ? onNameValid() : onNameInvalid();
  }

  public ngOnDestroy(): void {
    if (this.companyNameSubscription) {
      this.companyNameSubscription.unsubscribe();
    }
    if (this.fullNameSubscription) {
      this.fullNameSubscription.unsubscribe();
    }
    if (this.fullNameSubscription) {
      this.fullNameSubscription.unsubscribe();
    }
    if (this.companyTypeSubscription) {
      this.companyTypeSubscription.unsubscribe();
    }
  }

  public addSideEffect(form: FormGroup): void {
    this.form = form;

    this.companyTypeSubscription = this.createCompanyTypeSubscription();
    this.fullNameSubscription = this.createFullNameSubscription();
  }

  private createCompanyTypeSubscription(): Subscription {
    const companyType = this.form.get('companyInfo.companyType');

    const isPrivateEntrepreneur = value => {
      return value === 'privateEntrepreneur';
    };

    const unsubscribeCompanyNameSubscription = () => {
      if (this.companyNameSubscription) {
        this.companyNameSubscription.unsubscribe();
      }
    };

    const onPrivateEntrepreneur = () => {
      unsubscribeCompanyNameSubscription();
    };

    const onEnterprise = () => {
      unsubscribeCompanyNameSubscription();
      this.companyNameSubscription = this.createCompanyNameSubscription();
    };

    return companyType.valueChanges.subscribe(value => {
      isPrivateEntrepreneur(value) ? onPrivateEntrepreneur() : onEnterprise();
    });
  }

  private createCompanyNameSubscription(): Subscription {
    const companyName = this.form.get('debitCard.companyName');
    const companyDataForm = this.form.get(SectionType.CompanyData);

    return companyDataForm
      .get('companyDetails')
      .get('companyName')
      .valueChanges.subscribe(value => {
        if (value) {
          CardDataSideEffects.updateName(companyName, value);
        }
      });
  }

  private createFullNameSubscription(): Subscription {
    const trusteeDataForm = this.form.get('trusteeData');
    const fullName = trusteeDataForm.get('general.fullName');
    const nameOnTheCard = this.form.get('debitCard.nameOnTheCard');
    CardDataSideEffects.updateName(nameOnTheCard, fullName.value);

    return trusteeDataForm
      .get('general')
      .get('fullName')
      .valueChanges.subscribe(value => {
        CardDataSideEffects.updateName(nameOnTheCard, value);
      });
  }
}
