import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { AppConfigService } from '../../../app-config.service';
import {CognitoTokenDto} from './cognito-token-dto';

@Injectable()
export class UserSessionService {
  constructor(private configService: AppConfigService) {}

  private token: CognitoTokenDto;
  private expiresAt: Date;
  private tokenValidityInMinutes = 50;

  public get isLoggedIn(): boolean {
    return !!this.token
      ? Object.values(this.token)
          .map(token => !!token && this.checkTimeValidityOfToken())
          .reduce((previousValue, currentValue) => previousValue && currentValue, true)
      : false;
  }

  public set updateTokens(tokens: CognitoTokenDto) {
    this.token = tokens;
    this.expiresAt = new Date();
  }

  public checkTimeValidityOfToken(): boolean {
    const currentDate = new Date();
    // @ts-ignore
    const diffInMili = Math.abs(currentDate - this.expiresAt);
    const diffInMinutes = Math.floor((diffInMili / 1000) / 60);
    return diffInMinutes < this.tokenValidityInMinutes;
  }

  public isTokensModelValid(token: CognitoTokenDto): boolean {
    console.log(token, 'isTokensModelValid');
    console.log(!!token['accessToken'], 'BOOLEAN TEST');
    return !!token['access_token'];
  }

  public clearTokens(): void {
    this.token = null;
  }

  public isLoggedInObservable(): Observable<boolean> {
    return new Observable(subscriber => {
      const schedule = interval(100).subscribe(() => {
        subscriber.next(this.isLoggedIn);
        if (this.isLoggedIn) {
          schedule.unsubscribe();
          subscriber.complete();
        }
      });
    });
  }

  public get authorizationBffHeader(): {} {
    return this.authorizationHeader(this.token['access_token']);
  }

  public get authorizationCompanyRegistryHeader(): {} {
    return this.authorizationHeader(this.token['access_token']);
  }

  public get authorizationDocumentServiceHeader(): {} {
    return this.authorizationHeader(this.token['access_token']);
  }

  private authorizationHeader(token: string): {} {
    return { Authorization: `Bearer ${token}` };
  }

  private createCompanyRegistryTokenName(): string {
    return !!this.configService.config.useMockCompanyRegistry &&
      this.configService.config.useMockCompanyRegistry === 'true'
      ? 'company-registry-mock'
      : 'company-registry';
  }
}
