import { Injectable } from '@angular/core';
import { SmeonBffService } from './client/smeon-bff.service';
import { RbhuBankWorkingDayQueryV1Model } from '../../model/query-models/rbhu-bank-working-day-query-v1.model';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../app-config.service';

export interface BankWorkingDay {
  bankWorkingDay: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BankingDayService {
  constructor(private smeonBffService: SmeonBffService, private configService: AppConfigService) {}

  private _isBankingDay = true;

  public get isBankingDay(): boolean {
    return this._isBankingDay;
  }

  public checkBankingDay(): void {
    if (this.configService.config.skipBankWorkingDays !== 'true') {
      this.isBankingDayObservable().subscribe(result => {
        this._isBankingDay = result.bankWorkingDay;
      });
    }
  }

  private isBankingDayObservable(): Observable<BankWorkingDay> {
    const query = new RbhuBankWorkingDayQueryV1Model();

    return this.smeonBffService.query(query);
  }
}
