import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { VerificationRequest } from '../../../model/verification-request.model';
import { AppConfigService } from '../../../app-config.service';

@Injectable()
export class MerlinIdnvService {
  public ident = new Subject<String>();

  constructor(private appConfigService: AppConfigService, private http: HttpClient) {}

  public getAgents(): Observable<any> {
    console.log(this.appConfigService.config.idnvBaseUrlTest);
    const url =
      // this.appConfigService.config.idnvBaseUrl +
      // 'https://api-idnv.mserv-prod.merlinplatform.cloud/v1/agents/RBHU/rbhu-idnv-prod96';
      // this.appConfigService.config.idnvApplicationName;
      this.appConfigService.config.idnvBaseUrl + '/agents/RBHU/' + this.appConfigService.config.idnvApplicationName;

    const headers = {
      'Content-Type': 'application/json',
      'X-Ibm-Client-Id': this.appConfigService.config.idnvClientId,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers' : '*',
      'Access-Control-Allow-Methods' : '*'
    };

    return this.http.get(url, { headers: headers });
  }

  public createIdent(request: VerificationRequest, callback: () => void): void {
    // const url = this.appConfigService.config.idnvBaseUrl + '/merlin-idnv-online-svc/v1/verify';
    // const url = 'https://api-idnv.tmservices.merlinplatform.cloud/v1/verify'; // -->>>> URL PER TEST
    const url = 'https://api-idnv.mserv-prod.merlinplatform.cloud/v1/verify'; // ->>>>> URL PER PROD;

    const headers = {
      'Content-Type': 'application/json',
      'X-Ibm-Client-Id': this.appConfigService.config.idnvClientId,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers' : '*',
      'Access-Control-Allow-Methods' : '*'
    };

    this.http.post(url, request, { headers: headers }).subscribe((value: object) => {
      this.ident.next(value['id']);
      callback();
    });
  }
}
