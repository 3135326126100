import { Injectable, OnInit } from '@angular/core';
import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { AppConfigService } from '../../app-config.service';

@Injectable()
export class MapsConfigService implements LazyMapsAPILoaderConfigLiteral, OnInit {
  public apiKey: string;
  public libraries: string[];

  constructor(private environment: AppConfigService) {
    console.log(
      this.environment.loadAppConfig().then(value => {
        this.apiKey = this.environment.config.mapsApiKey;
      })
    );
  }

  public ngOnInit() {
    console.log(this.environment.config);
    this.apiKey = this.environment.config.mapsApiKey;
    this.libraries = [];
  }
}
