import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AcceptanceFormGroupBuilder } from './acceptance-form-group.builder';
import { Injectable } from '@angular/core';
import { SectionType } from '../../components/dynamic-section-creator/sections/section.type';

@Injectable({
  providedIn: 'root',
})
export class FormErrorsHandler {
  constructor(private acceptanceFormGroupBuilder: AcceptanceFormGroupBuilder) {}

  public handleFormErrorsOnNext(formGroup: FormGroup): string[] {
    const errors = [];

    if (this.isCountryOfBirthUsa(formGroup)) {
      errors.push('countryOfBirthUsa');
    }

    if (this.isNationalitiesInvalid(formGroup)) {
      errors.push('nationality');
    }

    return errors;
  }

  public handleFormErrorsOnSend(formGroup: FormGroup): string[] {
    const errors = [];

    const acceptanceForm = this.acceptanceFormGroupBuilder.getAcceptanceFormGroup();

    if (acceptanceForm.invalid) {
      this.markFormGroupTouched(acceptanceForm);
      errors.push('data');
    }

    if (formGroup.invalid) {
      this.markFormGroupTouched(formGroup);
      errors.push('data');
    }

    if (this.isNationalitiesInvalid(formGroup)) {
      errors.push('nationality');
    }

    if (this.isCountryOfBirthUsa(formGroup)) {
      errors.push('countryOfBirthUsa');
    }

    if (this.isBeneficiaryOwnerActorTypeNotNoRelated(formGroup)) {
      errors.push('related');
    }

    if (this.isBeneficiaryOwnersInvalid(formGroup)) {
      errors.push('beneficiaryOwnerNationality');
    }

    return errors;
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private isNationalitiesInvalid(formGroup: FormGroup): boolean {
    const isFormGroup = control => {
      return !!control.controls;
    };

    const hasNationalityError = control => {
      return !!(<FormControl>control).errors && (<FormControl>control).errors['nationality'];
    };

    return (<any>Object)
      .values(formGroup.controls)
      .map(control => {
        return isFormGroup(control)
          ? this.isNationalitiesInvalid(control)
          : hasNationalityError(control);
      })
      .reduce((v1, v2) => v1 || v2, false);
  }

  private isCountryOfBirthUsa(formGroup: FormGroup): boolean {
    const isFormGroup = control => {
      return !!control.controls;
    };

    const hasCountryOfBirthUsaError = control => {
      return !!(<FormControl>control).errors && (<FormControl>control).errors['countryOfBirthUsa'];
    };

    return (<any>Object)
      .values(formGroup.controls)
      .map(control => {
        return isFormGroup(control)
          ? this.isCountryOfBirthUsa(control)
          : hasCountryOfBirthUsaError(control);
      })
      .reduce((v1, v2) => v1 || v2, false);
  }

  private isBeneficiaryOwnerActorTypeNotNoRelated(formGroup): boolean {
    return (
      formGroup.get(SectionType.StatementsAndContracts).get('beneficiaryOwner.actorType').value !==
      'no-related'
    );
  }

  private isBeneficiaryOwnersInvalid(formGroup: FormGroup): boolean {
    const beneficiaryOwnerForm = <FormGroup>(
      formGroup.get(SectionType.StatementsAndContracts).get('beneficiaryOwner')
    );

    const additionalBeneficiaryOwners = (<FormArray>(
      formGroup.get(SectionType.StatementsAndContracts).get('additionalBeneficiaryOwner')
    )).controls;

    const isInvalid = (personForm: FormGroup): boolean => {
      return (
        personForm.get('general.nationality').value !== 'hu' ||
        personForm.get('general.countryOfBirth').value !== 'hu'
      );
    };

    return (
      isInvalid(beneficiaryOwnerForm) ||
      additionalBeneficiaryOwners
        .map(form => {
          return isInvalid(<FormGroup>form);
        })
        .reduce(function(val1, val2) {
          return val1 || val2;
        }, false)
    );
  }

  public handleAccountSelectorErrorsOnNext(formGroup: FormGroup) {
    return !!formGroup.get('accountSelector.selectedPackage').invalid ? ['package'] : [];
  }
}
