export const EventNames = {
  COMPANY_INFO: 'First step : Company Info',
  ACCOUNT_SELECTOR: 'Second step : Account Selector',
  COMPANY_DATA: 'Third step : Company Data',
  TRUSTEE_DATA: 'Fourth step : Trustee Data',
  DEBIT_CARD: 'Fifth step : Debit Card',
  STATEMENTS: 'Sixth step : Statements',
  VERIFICATION: 'Seventh step : Verification',
  THANK_YOU: 'Last step : Thank You Page',
};

export const EventDescriptions = {
};