import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { SectionType } from '../../../components/dynamic-section-creator/sections/section.type';

@Injectable()
export class CompanyDataSideEffectsService implements OnDestroy {
  private form: FormGroup;
  private mailingSameAsSeatSubscriptions: Subscription;
  private fatcaDisclosureSubscription: Subscription;

  public ngOnDestroy(): void {
    if (!!this.mailingSameAsSeatSubscriptions) {
      this.mailingSameAsSeatSubscriptions.unsubscribe();
    }

    if (!!this.fatcaDisclosureSubscription) {
      this.fatcaDisclosureSubscription.unsubscribe();
    }
  }

  public addSideEffect(form: FormGroup): void {
    this.form = form;
    this.mailingSameAsSeatSubscriptions = this.createMailingSameAsSeatSubscriptions();
    this.fatcaDisclosureSubscription = this.createFatcaDisclosureSubscription();
  }

  private copyAddressForm(copyFromForm: FormGroup, copyToForm: FormGroup): void {
    copyToForm.patchValue(copyFromForm.value);
  }

  private extractCompanyDataForm(form: FormGroup): FormGroup {
    return <FormGroup>form.get(SectionType.CompanyData);
  }

  private createMailingSameAsSeatSubscriptions() {
    const companyDataForm = this.extractCompanyDataForm(this.form);
    const seatForm = <FormGroup>companyDataForm.get('seat');
    const mailingForm = <FormGroup>companyDataForm.get('address');
    let permanentFormValueChangesSubscription: Subscription;

    const onMailingSameAsPermanentFunction = () => {
      this.copyAddressForm(seatForm, mailingForm);
      permanentFormValueChangesSubscription = seatForm.valueChanges.subscribe(value => {
        mailingForm.patchValue(value);
      });
      mailingForm.disable();
    };

    const onMailingNotSameAsPermanentFunction = () => {
      if (permanentFormValueChangesSubscription) {
        permanentFormValueChangesSubscription.unsubscribe();
      }
      mailingForm.patchValue({ addressField: '' });
      mailingForm.enable();
    };

    return companyDataForm.get('addressSameAsSeat').valueChanges.subscribe(value => {
      value ? onMailingSameAsPermanentFunction() : onMailingNotSameAsPermanentFunction();
    });
  }

  private createFatcaDisclosureSubscription(): Subscription {
    const fatcaDisclosure = this.extractCompanyDataForm(this.form).get('fatcaDisclosure');

    const addError = () => {
      let errors = fatcaDisclosure.errors;
      errors ? (errors.fatcaDisclosure = true) : (errors = { fatcaDisclosure: true });
      fatcaDisclosure.setErrors(errors);
    };

    const removeError = () => {
      if (!!fatcaDisclosure.errors) {
        delete fatcaDisclosure.errors['fatcaDisclosure'];
      }
    };

    return fatcaDisclosure.valueChanges.subscribe(value => {
      value === 'true' ? addError() : removeError();
    });
  }
}
