import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FileResults} from '../fileResults';
import {FormGroup} from '@angular/forms';
import {SectionType} from '../../../dynamic-section-creator/sections/section.type';
import {MerlinDocumentService} from '../../../../shared/service/client/merlin-document.service';
import {FormGroupBuilder} from '../../../../shared/form-group/form-group.builder';

@Component({
  selector: 'app-file-result-dialog',
  templateUrl: './file-result-dialog.component.html',
  styleUrls: ['./file-result-dialog.component.scss']
})
export class FileResultDialogComponent implements OnInit {

  public showFileSpinner: Boolean = true;
  public res: any;
  public typeOfRes: any;
  public formGroup: FormGroup;
  public sections = [
    SectionType.CompanyInfo,
    SectionType.AccountSelector,
    SectionType.CompanyData,
    SectionType.TrusteeData,
    SectionType.DebitCard,
    SectionType.StatementsAndContracts,
    SectionType.IdAndV,
  ];
  // tslint:disable-next-line:max-line-length
  constructor(public dialogRef: MatDialogRef<FileResultDialogComponent>) {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.res = FileResults.listOfInfectedFilesMap.get(sessionStorage.getItem('uuid'));
    console.log('Res ' + this.res);
    this.typeOfRes = typeof this.res;
  }

}
