import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../../app-config.service';
import { Observable } from 'rxjs';
import { BranchDTO } from '../../../model/branch-dt.model';
import { BranchesQueryV1 } from '../../../model/query-models/branches-query-v1.model';
import { SmeonBffService } from './smeon-bff.service';

export interface Branch {
  id: string;
  address: string;
  lng: string;
  lat: string;
  city: string;
}

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  constructor(
    private http: HttpClient,
    private environment: AppConfigService,
    private smeonBffService: SmeonBffService
  ) {}

  public resolveAddress(address: string) {
    const targetUrl =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      '&key=' +
      this.environment.config.mapsApiKey;
    return this.http.get(targetUrl);
  }

  public getBranchesList(): Observable<Branch[]> {
    return this.smeonBffService.query<Array<BranchDTO>>(new BranchesQueryV1());
  }
}
