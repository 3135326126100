import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { packagesOptions } from '../account-selector.component';

@Component({
  selector: 'app-income-range-selector',
  templateUrl: './income-range-selector.component.html',
  styleUrls: ['./income-range-selector.component.scss'],
})
export class IncomeRangeSelectorComponent implements OnInit {
  @Output()
  selectedLimit: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public disabled = false;

  public sliderItems = [
    { limit: '1.5M', tabIndex: packagesOptions.option3.tabIndex, selected: true },
    { limit: '5M', tabIndex: packagesOptions.option4.tabIndex, selected: false },
    { limit: '10M', tabIndex: packagesOptions.option5.tabIndex, selected: false },
    { limit: '22M', tabIndex: packagesOptions.option6.tabIndex, selected: false },
  ];

  public select(index: number) {
    if (this.disabled) {
      return;
    }
    this.emitSelectedLimit(this.sliderItems[index].tabIndex);
    for (let i = 0; i < this.sliderItems.length; i++) {
      index === i ? (this.sliderItems[i].selected = true) : (this.sliderItems[i].selected = false);
    }
  }

  public emitSelectedLimit(selectedLimit: any): void {
    this.selectedLimit.emit(selectedLimit);
  }

  public ngOnInit(): void {
    this.emitSelectedLimit(this.sliderItems[0].tabIndex);
  }
}
