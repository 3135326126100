import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { v4 as uuid } from 'uuid';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyRegistryService } from '../../../shared/service/client/company-registry.service';
import { SectionType } from '../../dynamic-section-creator/sections/section.type';
import { FormGroupBuilder } from '../../../shared/form-group/form-group.builder';
import {Observable, Subscription} from 'rxjs';
import { MatDialog, MatStepper } from '@angular/material';
import { MerlinIdnvService } from '../../../shared/service/client/merlin-idnv.service';
import { VerificationRequest } from '../../../model/verification-request.model';
import { BrexDatasetModel } from '../../../model/brex-dataset.model';
import { BrexDatasetHelper } from '../../../shared/brex-data-helper/brex-dataset.helper';
import { CaptchaTokensService } from '../../../shared/service/authentication/captcha-tokens.service';
import { UserSessionService } from '../../../shared/service/authentication/user-session.service';
import { TransactionIdService } from '../../../shared/service/transaction-id.service';
import { AppConfigService } from '../../../app-config.service';
import { MerlinDocumentService } from '../../../shared/service/client/merlin-document.service';
import { VerificationAddress } from '../../../model/verification-address.model';
import { PopupErrorComponent } from './popup-error-dialog/popup-error-dialog.component';
import { WorkingTimeService } from '../../../shared/service/working-time.service';
import { FormErrorsHandler } from '../../../shared/form-group/form-errors.handler';
import { ContactsFormGroupBuilder } from '../../../shared/form-group/contacts-form-group-builder.service';
import { ContactsPostService } from '../../../shared/service/contacts-post.service';
import { AcceptanceFormGroupBuilder } from '../../../shared/form-group/acceptance-form-group.builder';
import { DocumentDataSendingService } from '../../../shared/service/document-data-sending.service';
import { DocumentsIdsHelper } from '../../../shared/files-id-helper/documents-ids-helper.service';
import { StoreDocumentsService } from '../../../shared/service/store-documents.service';
import { PopupResumeSessionComponent } from './popup-update-session/popup-resume-session.component';
import { SpinnerService } from '../../../shared/service/spinner.service';
import { PopupBrowserDialogComponent } from './popup-browser-dialog/popup-browser-dialog.component';
import { IdentificationStatusService } from '../../../shared/service/identification-status.service';
import { Router } from '@angular/router';
import { BeforeUnloadService } from '../../../shared/service/before-unload.service';
import { RbhuFinalPageService } from '../../../shared/service/rbhu-final-page.service';
import {HttpClient} from '@angular/common/http';
import {FileResults} from './fileResults';
import {FileResultDialogComponent} from './file-result-dialog/file-result-dialog.component';
import {CountlyService} from '../../../shared/service/countly.service';

declare const InstallTrigger: any;
declare var Countly: any;


@Component({
  selector: 'app-open-account',
  templateUrl: './open-account.component.html',
  styleUrls: ['./open-account.component.scss'],
})
export class OpenAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper')
  stepper: MatStepper;

  public formGroup: FormGroup;
  public contactsFormGroup: FormGroup;
  public resOfScan: Map<String, String[]> = new Map<String, String[]>();
  public sections = [
    SectionType.CompanyInfo,
    SectionType.AccountSelector,
    SectionType.CompanyData,
    SectionType.TrusteeData,
    SectionType.DebitCard,
    SectionType.StatementsAndContracts,
    SectionType.IdAndV,
  ];
  private defaultFormRow: any;
  private companyTaxIdSubscription: Subscription;
  private userTaxIdSubscription: Subscription;
  private companyRegistrySubscription: Subscription;

  private resumeSessionInterval;

  constructor(
    private formGroupBuilder: FormGroupBuilder,
    private acceptenceFormGroupBuilder: AcceptanceFormGroupBuilder,
    private changeDetector: ChangeDetectorRef,
    private merlinIdnvService: MerlinIdnvService,
    private brexDatasetHelper: BrexDatasetHelper,
    private companyRegistryService: CompanyRegistryService,
    private captchaTokensService: CaptchaTokensService,
    private userSessionService: UserSessionService,
    private documentService: MerlinDocumentService,
    private transactionIdService: TransactionIdService,
    public environment: AppConfigService,
    public dialog: MatDialog,
    private workingTimeService: WorkingTimeService,
    private formErrorsHandler: FormErrorsHandler,
    private contactsFormGroupBuilder: ContactsFormGroupBuilder,
    private contactsPostService: ContactsPostService,
    private documentDataSendingService: DocumentDataSendingService,
    private documentsIdsHelper: DocumentsIdsHelper,
    private storeDocumentsService: StoreDocumentsService,
    private spinnerService: SpinnerService,
    private identificationStatusService: IdentificationStatusService,
    private router: Router,
    private beforeUnloadService: BeforeUnloadService,
    private thankYouPageService: RbhuFinalPageService,
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private spinner: SpinnerService,
    private countlyService: CountlyService
  ) {
  }

  public get isNotWorkingTime(): boolean {
    return false;
  }

  private get companyTypeFormControl(): FormControl {
    return <FormControl>this.formGroup.get('companyInfo.companyType');
  }

  ngOnInit(): void {
    this.beforeUnloadService.addEventListener();
    this.captchaTokensService.updateTokens();
    this.startResumeSessionTimer();
    this.formGroup = this.formGroupBuilder.create();
    this.initDefaultFormRow();
    this.contactsFormGroup = this.contactsFormGroupBuilder.getContactsFormGroup();
    Countly.q.push(['start_event', this.sections[this.stepper.selectedIndex]]);
    this.checkSupportBrowser();
  }

  public isNotFirstOrLastSection(section: SectionType): boolean {
    return (
      this.sections.indexOf(section) !== 0 &&
      this.sections.indexOf(section) !== this.sections.length - 1
    );
  }

  public isNotLastTwoSteps(section: SectionType): boolean {
    return this.sections.indexOf(section) < this.sections.length - 2;
  }

  public isOneBeforeLastSection(section: SectionType): boolean {
    return this.sections.indexOf(section) === this.sections.length - 2;
  }

  public stepperNext(sectionType: SectionType): void {
    this.markTouched(sectionType);

    const errors = this.isAccountSelectorSection()
      ? this.formErrorsHandler.handleAccountSelectorErrorsOnNext(this.formGroup)
      : this.formErrorsHandler.handleFormErrorsOnNext(this.formGroup);

    if (this.sections.indexOf(sectionType) === 0) {
      errors.length > 0 ? this.showErrorDialog(errors) : this.logEvent(sectionType);
    } else {
      errors.length > 0 ? this.showErrorDialog(errors) : this.stepper.next();
    }

  }
  public logEvent (section: SectionType) {
    this.spinner.showSpinner();
    const index = this.sections.indexOf(section);
    // tslint:disable-next-line:triple-equals
    if (index == 0) {
      this.handleFileScan();
    }
  }

  public onStepChange(event: any): void {
    // the stickiness of the stepper prevents scrolling on the top when a step gets changed
    document.body.scrollTop = 0;

    this.markTouched(event.previouslySelectedStep.label);
    Countly.q.push(['end_event', event.previouslySelectedStep.label]);
    Countly.q.push(['start_event', event.selectedStep.label]);

    if (this.isCompanyInfoSectionSelected()) {
      this.sendContactsForm();
      this.copyContactFormToCompanyData();
    }
  }

  public onSend(stepper: MatStepper, section: SectionType): void {
    const errors = this.formErrorsHandler.handleFormErrorsOnSend(this.formGroup);

    const onErrors = () => {
      if (!errors.includes('data')) {
        this.showErrorDialog(errors);
      }
    };

    const onWithoutErrors = () => {
      this.spinnerService.showSpinner();
      this.stepper._steps.forEach(step => (step.editable = false));

      const onDocumentsStored = () => {
        this.documentDataSendingService
          .sendDocumentDataToCCAgent(this.formGroup)
          .toPromise()
          .then(() => {
            this.sendIdnv(stepper);
          });
      };

      this.storeDocumentsService.uploadAndStoreDocuments(this.formGroup, onDocumentsStored());
    };

    errors.length > 0 ? onErrors() : onWithoutErrors();
  }

  public getSectionFormGroup(section: SectionType): FormGroup {
    return this.formGroup.get(section) as FormGroup;
  }

  public ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
    this.subscribeToCompanyTaxIdChanges();
    this.subscribeToUserTaxIdChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribeAllSubscriptions();
    clearInterval(this.resumeSessionInterval);
  }

  private markTouched(sectionType: SectionType) {
    const formGroup = <FormGroup>this.formGroup.get(sectionType);
    this.markFormGroupTouched(formGroup);

    if (this.isOneBeforeLastSection(sectionType)) {
      this.markAcceptanceFormGroupTouched();
    }
  }

  private isCompanyInfoSectionSelected(): boolean {
    return this.sections[this.stepper.selectedIndex] === SectionType.CompanyInfo;
  }

  private isAccountSelectorSection(): boolean {
    return this.sections[this.stepper.selectedIndex] === SectionType.AccountSelector;
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private isPrivateEntrepreneur = (): boolean => {
    return this.companyTypeFormControl.value === 'privateEntrepreneur';
  };

  private showErrorDialog(errors: string[]): void {
    this.dialog.open(PopupErrorComponent, {
      width: '500px',
      data: { errors: errors },
    });
  }

  private sendIdnv(stepper: MatStepper) {
    this.merlinIdnvService.getAgents().subscribe((value: any) => {
      const request = new VerificationRequest();
      request.agentId = Object.keys(value)[0];
      request.transactionId = this.transactionIdService.transactionId;

      const general = this.formGroup.get(SectionType.TrusteeData).get('general');
      const contact = this.formGroup.get(SectionType.TrusteeData).get('contact');

      const date: Date = new Date(general.get('dateOfBirth').value);

      const fullName = general.get('fullName').value;
      const lastName = String(fullName).split(' ')[0];
      const firstName = String(fullName)
        .split(' ')
        // tslint:disable-next-line:triple-equals no-shadowed-variable
        .filter((value, index) => index != 0)
        .reduce((e1, e2) => e1 + ' ' + e2, '')
        .trim();
      request.dateOfBirth =
        date.getFullYear() +
        '-' +
        this.pad(date.getMonth() + 1, 2) +
        '-' +
        this.pad(date.getDay() + 1, 2);
      request.placeOfBirth = general.get('cityOfBirth').value;
      request.birthName = general.get('birthFullName').value;
      request.trackingId = this.transactionIdService.transactionId;
      request.emailAddress = contact.get('primaryEmail').value;
      request.firstName = firstName;
      request.lastName = lastName;
      request.gender = general.get('gender').value === 'Férfi' ? 'MALE' : 'FEMALE';
      request.mobilePhoneNumber = '+36' + contact.get('primaryMobileNumber').value;
      request.nationality = general.get('nationality').value.toUpperCase();
      request.language = contact.get('correspondenceLanguage').value;

      const address = this.formGroup.get(SectionType.TrusteeData).get('address');
      request.address = new VerificationAddress({
        city: address.get('addressField').value,
      });

      this.merlinIdnvService.createIdent(request, () => {
        stepper.next();
        this.startTracingVerificationStatus();
      });
    });
  }

  private pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }

    return str;
  }

  private subscribeToCompanyTaxIdChanges() {
    this.companyTaxIdSubscription = this.formGroup
      .get('companyInfo.companyTaxId')
      .valueChanges.subscribe(() => {
        this.formGroup.patchValue(this.defaultFormRow);
        this.companyTypeFormControl.enable();
        this.clearEaktaFiles();
        if (this.isValidForGetData()) {
          this.updateForm();
        }
      });
  }

  private subscribeToUserTaxIdChanges() {
    this.userTaxIdSubscription = this.formGroup
      .get('companyInfo.userTaxId')
      .valueChanges.subscribe(() => {
        this.companyTypeFormControl.enable();
        this.formGroup.patchValue(this.defaultFormRow);
        this.clearEaktaFiles();
        if (this.isValidForGetData()) {
          this.updateForm();
        }
      });
  }

  private isValidForGetData(): boolean {
    const isCompanyTaxIdValid = (): boolean => {
      return this.formGroup.get('companyInfo.companyTaxId').valid;
    };

    const isUserTaxIdValid = (): boolean => {
      return this.formGroup.get('companyInfo.userTaxId').valid;
    };

    return this.isPrivateEntrepreneur()
      ? isCompanyTaxIdValid()
      : isCompanyTaxIdValid() && isUserTaxIdValid();
  }

  private updateForm(): void {
    if (this.isPrivateEntrepreneur()) {
      this.companyRegistrySubscription.unsubscribe();
      return;
    }
    const companyTaxId = this.formGroup.get('companyInfo.companyTaxId').value;
    const userTaxId = this.formGroup.get('companyInfo.userTaxId').value;

    const getPrivateEntrepreneur = () => {
      this.getCompanyData(companyTaxId);
    };

    const getEnterprise = () => {
      this.getCompanyData(companyTaxId, userTaxId);
    };

    this.userSessionService.isLoggedInObservable().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.isPrivateEntrepreneur() ? getPrivateEntrepreneur() : getEnterprise();
      }
    });
  }

  private getCompanyData(companyTaxId: string, userTaxId?: string): void {
    this.spinnerService.showSpinner();
    if (!!this.companyRegistrySubscription) {
      this.companyRegistrySubscription.unsubscribe();
    }
    this.formGroup.get('companyInfo.companyFetched').setValue('false');

    this.companyRegistrySubscription = this.companyRegistryService
      .getCompanyRegistry(companyTaxId, userTaxId)
      .subscribe(
        data => {
          const trimName = data.name;
          data.name = trimName.replace(/\s+$/, '');
          this.spinnerService.hideSpinner();
          this.formGroup.get('companyInfo.companyFetched').setValue('true');
          this.brexDatasetHelper.patchForm(this.formGroup, data as BrexDatasetModel);
        },
        error => {
          this.spinnerService.hideSpinner();
          this.formGroup.get('companyInfo.companyFetched').setValue('error');
          this.handleCompanyRegisterErrorResponse(error);
        }
      );
    sessionStorage.setItem('uuid', uuid());
  }

  private handleCompanyRegisterErrorResponse(error: any): void {
    const isCompanyNotFound =
      (error.status === 500 && error.error.code === 5002) || // in case Enterprise and invalid VAT number
      (error.status === 500 && error.error.code === 5000) || // in case PE and invalid VAT number
      (error.status === 404 && error.error.code === 40401 &&
        // tslint:disable-next-line:max-line-length
        this.formGroup.get(SectionType.CompanyInfo).get('companyType').value !== 'newCompany'); // in case valid VAT number and invalid tax id number

    const isCompanyTypeValidForManualMode =
      this.isPrivateEntrepreneur() ||
      this.formGroup.get(SectionType.CompanyInfo).get('companyType').value === 'newCompany';

    // tslint:disable-next-line:max-line-length
    isCompanyNotFound ? this.showErrorCompanyNotFound() : isCompanyTypeValidForManualMode ? this.setManualMode() : this.showErrorCompanyNotFound();
  }

  private showErrorCompanyNotFound(): void {
    const errors = ['companyNotFound'];
    this.showErrorDialog(errors);
  }

  private unsubscribeAllSubscriptions(): void {
    if (!!this.companyRegistrySubscription) {
      this.companyRegistrySubscription.unsubscribe();
    }
    if (!!this.userTaxIdSubscription) {
      this.userTaxIdSubscription.unsubscribe();
    }
  }

  private showResumeSessionDialog(): void {
    this.dialog
      .open(PopupResumeSessionComponent, {
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this.startResumeSessionTimer();
      });
  }

  private clearEaktaFiles(): void {
    this.formGroup.get('companyInfo.files').reset({});
  }

  private markAcceptanceFormGroupTouched(): void {
    this.markFormGroupTouched(this.acceptenceFormGroupBuilder.getAcceptanceFormGroup());
  }

  private sendContactsForm(): void {
    if (this.getSectionFormGroup(SectionType.CompanyInfo).valid) {
      this.contactsPostService.postContacts(this.contactsFormGroup).subscribe();
    }
  }

  private startResumeSessionTimer(): void {
    const intervalTime = 60 * 60 * 1000;

    this.resumeSessionInterval = setInterval(() => {
      this.showResumeSessionDialog();
      this.userSessionService.clearTokens();
      clearInterval(this.resumeSessionInterval);
    }, intervalTime);
  }

  private copyContactFormToCompanyData(): void {
    const contactDataPhoneNumberControl = this.contactsFormGroup.get('phoneNumber');
    const contactDataEmailControl = this.contactsFormGroup.get('email');
    const primaryPhoneNumberControl = this.formGroup.get(
      'companyData.contactDetails.primaryMobileNumber'
    );
    const primaryEmailControl = this.formGroup.get('companyData.contactDetails.primaryEmail');

    if (contactDataEmailControl.valid && !primaryEmailControl.value) {
      primaryEmailControl.patchValue(contactDataEmailControl.value);
    }

    if (contactDataPhoneNumberControl.valid && !primaryPhoneNumberControl.value) {
      primaryPhoneNumberControl.patchValue(contactDataPhoneNumberControl.value);
    }
  }

  private checkSupportBrowser(): void {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isChrome = !!window['chrome'];

    if (!isFirefox && !isChrome) {
      this.dialog.open(PopupBrowserDialogComponent, {
        width: '500px',
        disableClose: true,
      });
    }
  }

  private startTracingVerificationStatus(): void {
    this.identificationStatusService.isVerificationDone().subscribe(isVerificationDone => {
      if (isVerificationDone) {
        this.navigateToThankYouPage();
      }
    });
  }

  private navigateToThankYouPage(): void {
    this.beforeUnloadService.removeEventListener();
    this.thankYouPageService.redirectToThankYouPage('', '');
  }

  private setManualMode(): void {
    this.formGroup.get('companyInfo.companyFetched').setValue('manual');
    this.formGroup
      .get(SectionType.CompanyData)
      .get('companyDetails')
      .enable({ onlySelf: false });
    this.formGroup
      .get(SectionType.CompanyData)
      .get('companyDetails.vatNumber')
      .disable({});
    const companyTaxId = this.formGroup.get(SectionType.CompanyInfo).get('companyTaxId').value;
    this.formGroup
      .get(SectionType.CompanyData)
      .get('companyDetails.vatNumber')
      .patchValue(companyTaxId);
    this.formGroup
      .get(SectionType.CompanyData)
      .get('companyDetails.companyRegistrationNumber')
      .reset();
    this.formGroup
      .get(SectionType.TrusteeData)
      .get('general.fullName')
      .enable();
    this.formGroup
      .get(SectionType.TrusteeData)
      .get('general.mothersMaidenName')
      .enable();
    this.formGroup
      .get(SectionType.CompanyData)
      .get('seat')
      .enable();
    this.formGroup
      .get(SectionType.TrusteeData)
      .get('general.dateOfBirth')
      .enable();
  }

  private initDefaultFormRow(): void {
    this.defaultFormRow = this.formGroup.getRawValue();
    delete this.defaultFormRow.companyInfo.companyType;
    delete this.defaultFormRow.companyInfo.companyTaxId;
    delete this.defaultFormRow.companyInfo.userTaxId;
    delete this.defaultFormRow.statements.sameAsUser;
    delete this.defaultFormRow.statements.beneficiaryOwner.ownership;
    delete this.defaultFormRow.debitCard.companyName;
  }

  public handleFileScan (): void {
    const result: Promise<String> = this.upload();
    console.log('Resultttt ' + JSON.stringify(result));
  }

  public async upload(): Promise<any> {
    const companyInfoFiles = <FormGroup>this.formGroup.get(SectionType.CompanyInfo).get('files');
    const fileFields = Object.keys(companyInfoFiles.controls);
    const filesForUpload = fileFields.filter(p => !!companyInfoFiles.get(p).value);
    console.log('Length i files for upload ' + filesForUpload.length);
    FileResults.listOfInfectedFilesMap.delete(sessionStorage.getItem('uuid'));
    this.resOfScan.delete(sessionStorage.getItem('uuid'));
    await Promise.all(filesForUpload.map(async p => {
      const res = await this.documentService.scanFiles(companyInfoFiles.get(p).value);
      if (res !== undefined) {
        console.log(res.get(sessionStorage.getItem('uuid')));
        console.log('REZZZZ ' + res.get(sessionStorage.getItem('uuid')));
        this.resOfScan.set(sessionStorage.getItem('uuid'), res.get(sessionStorage.getItem('uuid')));
        companyInfoFiles.get(p).setErrors({ incorrectFileType: true});
      }
      console.log('Res mrena await ' + this.resOfScan);
    }));
    this.spinner.hideSpinner();
    console.log('U krytn kret');
    console.log('Res res ' + this.resOfScan.get(sessionStorage.getItem('uuid')));
    if (this.resOfScan.get(sessionStorage.getItem('uuid')) !== undefined) {
      this.openFileResultDialog();
    } else {
      this.stepper.next();
    }
    console.log('UUIDJA component ' + sessionStorage.getItem('uuid'));
    const result: String [] = FileResults.listOfInfectedFilesMap.get(sessionStorage.getItem('uuid'));
    return FileResults.listOfInfectedFilesMap.get(sessionStorage.getItem('uuid'));
  }

  openFileResultDialog(): void {
    console.log('U thirr eventi');
    const dialogRef = this.dialog.open(FileResultDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
