import { Component, OnInit } from '@angular/core';
import { SectionComponent } from '../section.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MerlinIdnvService } from '../../../../shared/service/client/merlin-idnv.service';
import { AppConfigService } from '../../../../app-config.service';
import { RbhuFinalPageService } from '../../../../shared/service/rbhu-final-page.service';
import { BeforeUnloadService } from '../../../../shared/service/before-unload.service';
import { SpinnerService } from '../../../../shared/service/spinner.service';
import {IdentificationStatusService} from '../../../../shared/service/identification-status.service';
import {CountlyService} from '../../../../shared/service/countly.service';
declare var Countly: any;
@Component({
  selector: 'app-id-v',
  templateUrl: './id-v.component.html',
  styleUrls: ['./id-v.component.scss'],
})
export class IdVComponent extends SectionComponent implements OnInit {
  public safeUrl: SafeResourceUrl = '';
  private invoked: boolean;

  constructor(
    public environment: AppConfigService,
    public sanitizer: DomSanitizer,
    private service: MerlinIdnvService,
    private router: Router,
    private rbhuThankYouPageService: RbhuFinalPageService,
    private beforeUnloadService: BeforeUnloadService,
    private spinnerService: SpinnerService,
    private configService: AppConfigService,
    private countlyService: CountlyService
  ) {
    super();
  }

  public isTokenValid(): boolean {
    return this.safeUrl.toString().length > 0;
  }

  public ngOnInit() {
    this.countlyService.trackVerification();
    this.service.ident.subscribe(val => {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.configService.config.idNowBaseUrl + val
      );
    });
    let observer = new MutationObserver((mutations, me) => {
        let xpath = '//*[@id="id-success-box"]/div[1]/div/h2';
        let element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        if (element) {
          console.log('Element is found', element);
          Countly.q.push(['end_event', 'verification']);
          me.disconnect(); // stop observing
          return;
        }
      });
      
    // start observing
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }

  public onIframeLoaded(event) {
    // the onload callback of the iframe is invoked 3 times. Once when ngIf is set to true,
    // one when the IDNow page is loaded and once because IDNow performs an internal redirect
    // the logic here waits for the second loading of the iframe to insure that IDNow is loaded
    // and only then hides the progress bar
    if (this.invoked) {
      this.spinnerService.hideSpinner();
    } else {
      this.invoked = true;
    }
    // window.location.href = 'https://szamlatnyitok.bangbang.hu/test/koszonjuk.php';
  }

  public thankYouClicked() {
    console.log('======== Thank you clicked =========');
    this.beforeUnloadService.removeEventListener();
    window.location.href = 'https://szamlatnyitok.raiffeisen.hu/koszonjuk.php';
    this.countlyService.trackThankYouPage();
    console.log('======== TEST ===========');
  }

  public get isTestEnvironment(): boolean {
    return !(
      this.environment.config.environmentName.startsWith('UAT') ||
      this.environment.config.environmentName.startsWith('PROD') ||
      this.environment.config.environmentName.startsWith('DEV') ||
      this.environment.config.environmentName.startsWith('SIT')
    );
  }
}
