import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface DialogData {
  errors: Array<String>;
}

@Component({
  templateUrl: './popup-error-dialog.component.html',
})
export class PopupErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
