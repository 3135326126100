import { SectionType } from '../../components/dynamic-section-creator/sections/section.type';
import { Injectable } from '@angular/core';
import { BrexDatasetModel } from '../../model/brex-dataset.model';

import { FormGroup } from '@angular/forms';

@Injectable()
export class BrexDatasetHelper {
  private get financialInstitutionTEAORCode(): string[] {
    return ['6419', '6430', '6491', '6492', '6499', '6511', '6530', '6611', '6612', '6619', '6630'];
  }

  public isPrivateEntrepreneur(form: FormGroup): boolean {
    const companyType = form.get('companyInfo.companyType').value;
    return companyType === 'privateEntrepreneur';
  }

  public patchForm(form: FormGroup, brexDataset: BrexDatasetModel): void {
    const companyInfoFormGroup = form.get(SectionType.CompanyInfo);
    const companyTypeFormControl = companyInfoFormGroup.get('companyType');

    if (
      !this.isPrivateEntrepreneur(form) &&
      !this.isRegistrationNumberValid(brexDataset.registrationNumber, form)
    ) {
      companyTypeFormControl.setErrors({ pattern: true });
    } else {
      companyTypeFormControl.setErrors(null);
      companyInfoFormGroup.get('isBookKeeper').patchValue(this.isBookKeeper(brexDataset));
      this.patchCompanyData(form, brexDataset);
      this.patchEntitledToSignUser(form, brexDataset);
    }
  }

  private isFinancialInstitution(mainTEAORCode: string) {
    if (!mainTEAORCode) {
      return false;
    }

    return this.financialInstitutionTEAORCode
      .map(value => {
        return mainTEAORCode === value;
      })
      .reduce(function(val1, val2) {
        return val1 || val2;
      }, false);
  }

  private isBookKeeper(brexDataset: BrexDatasetModel): boolean {
    return brexDataset.additionalData.mainTEAORCode.toString() === '6920';
  }

  private patchCompanyData(form: FormGroup, brexDataset: BrexDatasetModel): void {
    let regNumber: string = brexDataset.registrationNumber;

    if (!this.isPrivateEntrepreneur(form)) {
      regNumber = regNumber.substr(0, 2) + '-' + regNumber.substr(2, 2) + '-' + regNumber.substr(4);
    }

    form.get('companyData.companyDetails.companyRegistrationNumber').patchValue(regNumber);

    form.get('companyData.companyDetails.companyName').patchValue(brexDataset.name);
    this.handleCompanyRegistrationNumber(form, brexDataset.registrationNumber);

    form.get('companyData.companyDetails.vatNumber').patchValue(brexDataset.vatNumber);
    form.get('companyData.companyDetails').disable({ onlySelf: false });

    const mainTEAORCode = brexDataset.additionalData.mainTEAORCode;
    form
      .get('companyData.isFinancialInstitution')
      .patchValue(this.isFinancialInstitution(mainTEAORCode));
    if (form.get('companyData.isFinancialInstitution').value) {
      form.get('companyData.fatcaDisclosure').enable();
    }

    const naceCode = brexDataset.additionalData.mainTEAORText + ' (' + mainTEAORCode + ')';

    brexDataset.additionalData.mainTEAORText
      ? form.get('companyData.companyDetails.naceCode').patchValue(naceCode)
      : form.get('companyData.companyDetails.naceCode').patchValue('');

    if (!!brexDataset.address) {
      form.get('companyData.seat.addressField').patchValue(brexDataset.address);
      form.get('companyData.seat.addressField').disable();
    } else {
      form.get('companyData.seat.addressField').enable();
    }

    form
      .get('companyData.providedTaxNumStatus')
      .patchValue(brexDataset.additionalData.providedTaxNumStatus);
    form.get('companyData.statusText').patchValue(brexDataset.additionalData.statusText);
    form.get('companyData.status').patchValue(brexDataset.status);
  }

  private patchEntitledToSignUser(form: FormGroup, brexDataset: BrexDatasetModel): void {
    if (this.isPrivateEntrepreneur(form)) {
      form.get('trusteeData.general.fullName').patchValue(brexDataset.name);
    } else {
      const personTaxId = form.get(SectionType.CompanyInfo).get('userTaxId').value;
      const entitledToSign = brexDataset.entitledToSign.filter(e => e.personId === personTaxId).reduce(e2 => e2);

      form.get('trusteeData.general.fullName').patchValue(entitledToSign.name);
      form.get('trusteeData.general.birthFullName').patchValue(entitledToSign.birthName);
      form.get('trusteeData.general.mothersMaidenName').patchValue(entitledToSign.mothersName);
      const date = entitledToSign.birthDate.split('.');
      const finalDate = date[0] + '-' + date[1] + '-' + date[2];
      form.get('trusteeData.general.dateOfBirth').patchValue(new Date(finalDate));
      form
        .get('trusteeData.general.representationType')
        .patchValue(entitledToSign.representationType);
    }
  }

  private isRegistrationNumberValid(registrationNumber: string, form: FormGroup): boolean {
    return !!this.registrationNumberCompanyTypeMatching(registrationNumber, form);
  }

  private handleCompanyRegistrationNumber(form: FormGroup, registrationNumber: string): void {
    const companyType = this.registrationNumberCompanyTypeMatching(registrationNumber, form);
    const companyTypeFormControl = form.get(SectionType.CompanyInfo).get('companyType');

    if (
      !!companyType &&
      companyTypeFormControl.value !== companyType &&
      !this.isNewCompany(companyTypeFormControl.value)
    ) {
      companyTypeFormControl.patchValue(companyType);
      companyTypeFormControl.disable();
    }
  }

  private isNewCompany(companyType: string): boolean {
    return companyType === 'newCompany';
  }

  private registrationNumberCompanyTypeMatching(registrationNumber: string, form: FormGroup): string {
    const extractedCompanyTypeCode = registrationNumber.substring(2, 4);
    if (this.isPrivateEntrepreneur(form)) {
      return null;
    }
    switch (extractedCompanyTypeCode) {
      case '11':
        return 'individualCompany';
      case '06':
        return 'limitedLiability';
      case '09':
        return 'limitedLiability';
      case '03':
        return 'generalPartnership';
      case '02':
        return 'cooperative';
      default:
        return null;
    }
  }
}
