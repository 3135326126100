import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService {
  public config;
  private promise;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    if (!!this.promise) {
      return this.promise;
    }
    this.promise = this.http.get('/assets/config.json').toPromise();

    this.promise.then(data => {
      this.config = data;
      return data;
    });
    return this.promise;
  }
}