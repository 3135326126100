import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SectionComponent } from '../section.component';
import {CountlyService} from '../../../../shared/service/countly.service';

@Component({
  selector: 'app-trustee-data',
  templateUrl: './trustee-data.component.html',
  styleUrls: ['./trustee-data.component.scss'],
})
export class TrusteeDataComponent extends SectionComponent implements OnInit, OnDestroy {
  private companyTypeSubscription: Subscription;
  constructor(private countlyService: CountlyService) {
    super();
  }
  public onlyPastFilter = (d: Date): boolean => {
    return d.getTime() < Date.now();
  };

  public onlyFutureFilter = (d: Date): boolean => {
    return d.getTime() > Date.now();
  };
  public get isAdditionalMobileNumberNotEmpty(): boolean {
    return !!this.sectionFormGroup.get('contact.additionalMobileNumber').value;
  }

  public ngOnInit(): void {
    this.countlyService.trackTrusteeData();
    this.sectionFormGroup.get('contact.correspondenceLanguage').setValue('hu');
    this.sectionFormGroup.get('contact.correspondenceLanguage').disable();
    this.subscribeToCompanyTypeChanges();
  }

  public ngOnDestroy(): void {
    this.companyTypeSubscription.unsubscribe();
  }

  private subscribeToCompanyTypeChanges() {
    this.companyTypeSubscription = this.formGroup
      .get('companyInfo.companyType')
      .valueChanges.subscribe(value => {
        value === 'privateEntrepreneur' ? this.setFormFieldsEnabled() : this.disableFormFields();
      });
  }

  private disableFormFields(): void {
    this.sectionFormGroup.get('address').disable({ onlySelf: false });
    this.sectionFormGroup.get('general.fullName').disable();
    this.sectionFormGroup.get('general.dateOfBirth').disable();
    this.sectionFormGroup.get('general.mothersMaidenName').disable();
  }

  private setFormFieldsEnabled(): void {
    this.sectionFormGroup.get('address').enable({ onlySelf: false });
    this.sectionFormGroup.get('general.fullName').enable();
    this.sectionFormGroup.get('general.dateOfBirth').enable();
    this.sectionFormGroup.get('general.mothersMaidenName').enable();
  }

  public get sameAsCompanyMobile(): boolean {
    return this.sectionFormGroup.get('primaryMobileNumberSameAsCompanyMobile').value;
  }

  public get isPrimaryEmailSameAsCompanyEmailEnabled(): boolean {
    return this.sectionFormGroup.get('primaryEmailSameAsCompanyEmail').enabled;
  }

  public get isPrimaryMobileNumberSameAsCompanyMobileEnabled(): boolean {
    return this.sectionFormGroup.get('primaryMobileNumberSameAsCompanyMobile').enabled;
  }

  public get isLandLinePhoneNumberSameAsCompanyMobileEnabled(): boolean {
    return this.sectionFormGroup.get('landLinePhoneNumberSameAsCompanyLandLine').enabled;
  }

  public onAddPhoneChange(isDisplayed: boolean): void {
    if (!isDisplayed) {
      this.sectionFormGroup.get('contact.additionalMobileNumber').reset();
    }
  }
}
