import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationMessagesHelper } from '../../../../../shared/validation-messages/validation-messages.helper';

@Component({
  selector: 'app-beneficiary-owner',
  templateUrl: './beneficiary-owner.component.html',
  styleUrls: ['./beneficiary-owner.component.scss'],
})
export class BeneficiaryOwnerComponent {
  @Input()
  public formGroup: FormGroup;

  @Input()
  public additional: boolean;

  @Input()
  public additionalIndex: number;

  constructor(private validationMessagesHelper: ValidationMessagesHelper) {}

  public get general(): FormGroup {
    return <FormGroup>this.formGroup.get('general');
  }

  public get address(): FormGroup {
    return <FormGroup>this.formGroup.get('address');
  }

  public get mailing(): FormGroup {
    return <FormGroup>this.formGroup.get('mailing');
  }

  public get contact(): FormGroup {
    return <FormGroup>this.formGroup.get('contact');
  }

  public get ownership(): FormGroup {
    return <FormGroup>this.formGroup.get('ownership');
  }

  public get taxResidency(): FormGroup {
    return <FormGroup>this.formGroup.get('taxResidency');
  }

  public getErrorMessages(controlKey: string): string {
    const control = this.formGroup.get(controlKey) as FormControl;

    return this.validationMessagesHelper.createErrorMessages(control);
  }

  public isInvalid(controlKey: string): boolean {
    return (this.formGroup.get(controlKey) as FormControl).invalid;
  }

  public handleId(id: string): string {
    return this.additional ? id + '-' + this.additionalIndex : id;
  }
}
