import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationMessagesHelper } from '../../shared/validation-messages/validation-messages.helper';
import { ErrorFocusService } from '../../shared/service/error-focus.service';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss'],
})
export class ContactDataComponent {
  @Input()
  public formGroup: FormControl;

  @Input()
  public required: boolean;

  constructor(private validationMessagesHelper: ValidationMessagesHelper) {}

  public getErrorMessages(controlKey: string): string {
    const control = this.formGroup.get(controlKey) as FormControl;

    return this.validationMessagesHelper.createErrorMessages(control);
  }

  public isInvalid(controlKey: string): boolean {
    const isInvalid = (this.formGroup.get(controlKey) as FormControl).invalid;
    if (isInvalid) {
      ErrorFocusService.focusOnFirstInvalidField();
    }
    return isInvalid;
  }

  public landLinePhoneMask(controlKey: string): string {
    return String(this.formGroup.get(controlKey).value).startsWith('1')
      ? '0 000 00999'
      : '00 000 0999';
  }

  public mobilePhoneMask(): string {
    return '00 0000000';
  }
}
