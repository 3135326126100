import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nationality-list',
  templateUrl: './nationality-list.component.html',
  styleUrls: ['./nationality-list.component.scss'],
})
export class NationalityListComponent implements OnInit, OnDestroy {
  @Input()
  public formGroup: FormGroup;

  private nationalityChangeSubscription: Subscription;

  public get control(): FormControl {
    return <FormControl>this.formGroup.get('nationality');
  }

  public get nationalities() {
    return ['hu', 'other'];
  }

  public ngOnInit(): void {
    this.control.patchValue('hu');
    this.nationalityChangeSubscription = this.createNationalityChangeSubscription();
  }

  public ngOnDestroy(): void {
    if (!!this.nationalityChangeSubscription) {
      this.nationalityChangeSubscription.unsubscribe();
    }
  }

  public createNationalityChangeSubscription(): Subscription {
    const isNationalityHungary = () => {
      return this.control.value === 'hu';
    };

    const addError = () => {
      let errors = this.control.errors;
      errors ? (errors.nationality = true) : (errors = { nationality: true });
      this.control.setErrors(errors);
    };

    const removeError = () => {
      if (!!this.control.errors) {
        delete this.control.errors['nationality'];
      }
    };

    return this.control.valueChanges.subscribe(() => {
      isNationalityHungary() ? removeError() : addError();
    });
  }
}
