import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileType } from './file.type';
import { ValidationMessagesHelper } from '../../shared/validation-messages/validation-messages.helper';
import {OpenAccountComponent} from '../pages/open-account/open-account.component';
import {MerlinDocumentService} from '../../shared/service/client/merlin-document.service';
import {CountlyService} from '../../shared/service/countly.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input()
  public control: FormControl;

  @Input()
  public placeholder: string;

  @Input()
  public fileTypes: FileType[];

  @Input()
  public buttonId: string;

  @ViewChild('fileInput')
  private fileInput: ElementRef;

  private maxFileSize = 15 * 1024 * 1024;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private validationMessagesHelper: ValidationMessagesHelper,
    private openAccComponent: OpenAccountComponent,
    private documentService: MerlinDocumentService,
    private countlyService: CountlyService
  ) {}

  public get fileName(): string {
    return this.isFileSelected
      ? this.filePath.substring(this.filePath.lastIndexOf('\\') + 1, this.filePath.length)
      : '';
  }

  public get selectedFileType(): string {
    return this.isFileSelected
      ? this.filePath.substring(this.filePath.lastIndexOf('.'), this.filePath.length)
      : '';
  }

  public get isFileSelected(): boolean {
    return !!this.filePath && this.filePath.length > 0;
  }

  public get isInvalid(): boolean {
    return this.control.invalid;
  }

  public get isTouched(): boolean {
    return this.control.touched;
  }

  private get filePath(): string {
    return this.fileInput.nativeElement.value;
  }

  public onFileChange(event: any): void {
    const file = event.target.files[0];
    this.isFileValid(file) ? this.onFileInvalid(file) : this.onFileValid(file);
  }

  public openFolder(): void {
    this.fileInput.nativeElement.click();
    this.control.markAsTouched();
  }

  public onRemove(): void {
    this.fileInput.nativeElement.value = '';
    this.control.reset();
  }

  public getErrorMessages(): string {
    return this.validationMessagesHelper.createErrorMessages(this.control);
  }

  private onFileInvalid = (file: File) => {
    if (this.isFileToBig(file)) {
      this.control.setErrors({ invalidFileSize: true, ...this.control.errors });
    }
    if (this.isFileTypeInvalid()) {
      this.control.setErrors({ incorrectFileType: true, ...this.control.errors });
    }
  };

  private onFileValid = (file: File) => {
    this.control.setErrors(null);

    this.control.patchValue(file);
    // @ts-ignore
    sessionStorage.setItem('file', file);
    console.log('File nameeee ' + file.name);
    // this.documentService.scanFiles(file);
  };

  private isFileValid(file: File): boolean {
    return this.isFileTypeInvalid() || this.isFileToBig(file);
  }

  private isFileTypeInvalid(): boolean {
    return !this.fileTypes
      .map(fileType => fileType === this.selectedFileType.toLowerCase())
      .reduce((e1, e2) => e1 || e2, false);
  }

  private isFileToBig(file: File): boolean {
    return file.size > this.maxFileSize;
  }
}
