import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SectionComponent} from '../section.component';
import {FormGroupBuilder} from '../../../../shared/form-group/form-group.builder';
import {SectionType} from '../section.type';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AcceptanceFormGroupBuilder} from '../../../../shared/form-group/acceptance-form-group.builder';
import {FormPostService} from '../../../../shared/service/form-post.service';
import {SpinnerService} from '../../../../shared/service/spinner.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {PlannedTransactionErrorModalComponent} from './planned-transaction-error-modal/planned-transaction-error-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {CountlyService} from '../../../../shared/service/countly.service';

@Component({
  selector: 'app-statements-contracts',
  templateUrl: './statements-contracts.component.html',
  styleUrls: ['./statements-contracts.component.scss'],
})
export class StatementsContractsComponent extends SectionComponent implements OnInit, OnDestroy {
  public legalDocuments = [
    'paymentAccountContract',
    'paymentAccountT&C',
    'corporateListOfConditions',
    'generalBusinessConditions',
    'nationalDepositGuaranteeFund',
  ];

  public acceptanceForm: FormGroup;

  public companyTypeSubscription: Subscription;
  public matDialogRef!: MatDialogRef<PlannedTransactionErrorModalComponent>;
  public firstQuestionTranslated: String;
  public secondQuestionTranslated: String;

  public constructor(
    private formGroupBuilder: FormGroupBuilder,
    private acceptanceFormGroupBuilder: AcceptanceFormGroupBuilder,
    private changeDetector: ChangeDetectorRef,
    private formPostService: FormPostService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private countlyService: CountlyService
  ) {
    super();
  }

  public get additionalBo(): FormArray {
    return <FormArray>this.sectionFormGroup.get('additionalBeneficiaryOwner');
  }

  public get additionalBoControls(): Array<AbstractControl> {
    const controls = [];
    for (let i = 0; i < this.additionalBo.length; i++) {
      controls.push(this.additionalBo.at(i));
    }

    return controls;
  }

  public get isLastPdfDownloaded(): boolean {
    return this.acceptanceForm.get('isLastPdfDownloaded').value;
  }

  public get isCrsAndFatcaWereRead(): boolean {
    return this.acceptanceForm.get('isCrsAndFatcaWereRead').value;
  }

  public get acceptanceCartValidationStatus(): string {
    return this.getFormControlsValidationStatus(
      ['isLastPdfDownloaded', 'isAgreedDataStoring', 'isAccepted', 'isCrsAndFatcaWereRead'],
      this.acceptanceForm
    );
  }

  public get acceptanceCartValidationStatusForCheckboxes(): string {
    return this.getFormControlsValidationStatus(
      ['question1', 'question2'],
      this.acceptanceForm
    );
  }

  public static matchCompanyTypeCode(companyType: any): string {
    switch (companyType) {
      case 'privateEntrepreneur':
        return 'PE';
      case 'limitedLiability':
        return 'LN';
      case 'newCompany':
        return 'NC';
      case 'individualCompany':
        return 'IC';
      case 'cooperative':
        return 'CO';
      case 'generalPartnership':
        return 'GP';
      default:
        return '';
    }
  }

  public landLinePhoneMask(controlKey: string): string {
    return super.landLinePhoneMask(controlKey);
  }

  public ngOnInit(): void {
    this.countlyService.trackStatements();
    this.companyTypeSubscription = this.createCompanyTypeSubscription();
    this.acceptanceForm = this.acceptanceFormGroupBuilder.getAcceptanceFormGroup();
    this.acceptanceForm.get('isAgreedDataStoring').disable();
    this.acceptanceForm.get('isAccepted').disable();
    this.sectionFormGroup.get('beneficiaryOwner.general.countryOfBirthOther').disable();
    this.translateService.get('plannedTransaction.firstQuestion').subscribe( (data) => {
      this.firstQuestionTranslated = data;
    });
    this.translateService.get('plannedTransaction.secondQuestion').subscribe( (data) => {
      this.secondQuestionTranslated = data;
    });
  }

  public ngOnDestroy(): void {
    if (this.companyTypeSubscription) {
      this.companyTypeSubscription.unsubscribe();
    }
  }

  public addBo(): void {
    this.additionalBo.push(this.formGroupBuilder.createAdditionalBeneficiaryForm());
    this.changeDetector.detectChanges();
  }

  public removeBo(i): void {
    this.additionalBo.removeAt(i);
    this.changeDetector.detectChanges();
  }

  public onDownloadPdf(): void {
    this.generateApplicationId();

    if (this.sectionFormGroup.valid) {
      console.log('Forma ' + JSON.stringify(this.formGroup.getRawValue()));
      console.log(' Acceptance ' + JSON.stringify(this.acceptanceForm.getRawValue()));
      this.formGroup.addControl('question1', new FormControl());
      this.formGroup.addControl('question2', new FormControl());
      this.formGroup.patchValue({
        question1: this.acceptanceForm.get('question1').value,
        question2: this.acceptanceForm.get('question2').value
      });
      console.log('Forma pas modifikimit ' + JSON.stringify(this.formGroup.getRawValue()));
      this.spinnerService.showSpinner();
      this.formPostService.openPostedForm(this.formGroup).subscribe(success => {
        if (!!success) {
          const isLastPdfDownloadedControl = this.acceptanceForm.get('isLastPdfDownloaded');
          isLastPdfDownloadedControl.patchValue(true);
          isLastPdfDownloadedControl.markAsTouched();
          const isAgreedDataStoringControl = this.acceptanceForm.get('isAgreedDataStoring');
          isAgreedDataStoringControl.enable();
          isAgreedDataStoringControl.markAsTouched();
        }
        this.spinnerService.hideSpinner();
      });
    }
  }

  public openCrsAndFatcaLink(): void {
    window.open(
      'https://www.raiffeisen.hu/hasznos/kondiciok/online-szerzodeskotes-kisvallalat',
      '_blank'
    );
    this.acceptanceForm.get('isCrsAndFatcaWereRead').patchValue(true);
    this.acceptanceForm.get('isAccepted').enable();
  }

  private createCompanyTypeSubscription(): Subscription {
    return this.formGroup
      .get(SectionType.CompanyInfo)
      .get('companyType')
      .valueChanges.subscribe(() => {
        if (this.isPrivateEntrepreneur) {
          this.sectionFormGroup.get('sameAsUser').setValue(true);
          while (this.additionalBo.length > 0) {
            this.additionalBo.removeAt(0);
          }
        }
      });
  }

  private generateApplicationId(): void {
    const companyType = this.formGroup.get('companyInfo.companyType').value;
    const companyTypeCode = StatementsContractsComponent.matchCompanyTypeCode(companyType);
    const random = Math.floor(Math.random() * 100000 + 1);
    console.log('RANDOM NUMBER ------------>', random);
    this.formGroup.get('applicationId').patchValue(companyTypeCode + '-' + random);
    console.log('APPLICATION ID ------------->', this.formGroup.get('applicationId'));
  }

  openModalForAmlPlannedTransactions() {
    this.matDialogRef = this.dialog.open(PlannedTransactionErrorModalComponent);
    this.matDialogRef.afterClosed().subscribe(result => {
      this.acceptanceForm.get('question1').setValue('');
    });
  }

  ifQuestionCardIsValid(): boolean {
    if (this.acceptanceForm.get('question1').hasError('required') || this.acceptanceForm.get('question2').hasError('required')) {
      return false;
    } else {
      return true;
    }
  }
}
